<template>
<div>
    <span class="ivy-part"> IVY</span><span class="ed-part">ED</span>
</div>
</template>

<script>
    export default {
        name: "IvyedLogo"
    }
</script>

<style scoped>
    .ivy-part {
        font-size: 50px;
        font-weight: bold;
    }
    .ed-part {
        font-size: 43px;
        font-weight: bold;
    }

</style>
