<template>
    <div>
        <v-expansion-panels>
            <v-expansion-panel class="ma-2" v-for="student in userStudents" :key="student.id">

                <v-expansion-panel-header >
                    <div>
                    student id:<strong> {{student.id}} </strong>/ email: <strong> {{student.email}}</strong>                    <span style="font-size: 20px; color: #2200cc; font-weight: bold"><strong>총 남은 횟수:</strong> {{student.student.sumOfRemainingClasses}}<br/></span>

                        <!--                    <strong>student id:</strong> {{student.id}} - -->
<!--                    <strong>student email:</strong> {{student.email}}-->
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-card class="pa-4">

                    {{student.student.studentRegisterStatus}} <br/>
                    <strong>student id:</strong> {{student.id}} <br/>
                    <strong>student email:</strong> {{student.email}} <br/>
                    <strong>student phone:</strong> {{student.countryCode}}{{student.phoneNumber}} <br/>
                    <strong>과목:</strong> {{student.student.subjectsOfInterest[0]}} -
                    {{student.student.subSubjects}}<br/>
                    <strong>선호요일:</strong> {{student.student.availableDays}} <br/>
                    <strong>선호시간:</strong> {{student.student.detailedLessonTimes}} <br/>
                    <strong>kakao:</strong> {{student.student.kakaoId}}<br/>
                    <span style="font-size: 20px; color: #2200cc; font-weight: bold"><strong>총 남은 횟수:</strong> {{student.student.sumOfRemainingClasses}}<br/></span>
                    <div v-for="payment in student.student.payments" :key="payment.id">

                        <v-card color="blue" class="pa-3 ma-1" v-if="payment.autoPayment">
                            <v-card-title><strong>최초 학생 결제 내역: </strong></v-card-title>

                            <strong>payment ID: </strong>{{payment.id}} <br/>
                            <strong>payment date: </strong>{{payment.createdAt}} <br/>
                            <strong>최초 구입한 수강권 기간 = </strong>{{student.student.expectedLessonDuration}}개월 <br/>
                            <strong>최초 구입한 수강권 수업 횟수 / week = </strong>{{student.student.expectedNumberOfLessonsPerWeek}}회
                            <br/>
                            <strong>최초 수강권 결제 액수 = </strong>{{payment.finalPrice}}원<br/>
                            <strong>최초 구입한 총 수강권 = </strong>{{payment.totalNumberOfClasses}} <br/>
                            <!--                        <strong>잔여 수업 = </strong>{{payment.remainingNumberOfClasses}} <br/>-->
                            <v-divider></v-divider>
                            <strong>최초 구매한 수강권 기준 한달 분 가격: </strong> {{payment.finalPrice}} /
                            {{student.student.expectedLessonDuration}} =
                            {{(payment.finalPrice/student.student.expectedLessonDuration).toLocaleString()}}원 <br/>
                            <strong>최초 구매한 수강권 기준 한달 분 횟수: </strong>
                            주{{student.student.expectedNumberOfLessonsPerWeek}}회 *
                            4주 = 총{{student.student.expectedNumberOfLessonsPerWeek * 4}}회
                            <!--                    <strong>payments:</strong> {{sltudent.student.payments}}<br/>    -->

                        </v-card>
                        <v-card color="green" class="pa-3 ma-1" v-if="!payment.autoPayment">
                            <v-card-title><strong>추가 결제</strong></v-card-title>

                            <strong>payment ID: </strong>{{payment.id}} <br/>
                            <strong>payment date: </strong>{{payment.createdAt}} <br/>
                            <strong>결제 금액: </strong>{{payment.createdAt}} <br/>
                            <strong>수업 횟수: </strong>{{payment.remainingNumberOfClasses}} <br/>
<!--                            <strong>최초 구입한 수강권 기간 = </strong>{{student.student.expectedLessonDuration}}개월 <br/>-->
<!--                            <strong>최초 구입한 수강권 수업 횟수 / week = </strong>{{student.student.expectedNumberOfLessonsPerWeek}}회-->
<!--                            <br/>-->
<!--                            <strong>최초 수강권 결제 액수 = </strong>{{payment.finalPrice}}원<br/>-->
<!--                            <strong>최초 구입한 총 수강권 = </strong>{{payment.totalNumberOfClasses}} <br/>-->
                            <!--                        <strong>잔여 수업 = </strong>{{payment.remainingNumberOfClasses}} <br/>-->
                            <v-divider></v-divider>
<!--                            <strong>최초 구매한 수강권 기준 한달 분 가격: </strong> {{payment.finalPrice}} /-->
<!--                            {{student.student.expectedLessonDuration}} =-->
<!--                            {{(payment.finalPrice/student.student.expectedLessonDuration).toLocaleString()}}원 <br/>-->
<!--                            <strong>최초 구매한 수강권 기준 한달 분 횟수: </strong>-->
<!--                            주{{student.student.expectedNumberOfLessonsPerWeek}}회 *-->
<!--                            4주 = 총{{student.student.expectedNumberOfLessonsPerWeek * 4}}회-->
                            <!--                    <strong>payments:</strong> {{sltudent.student.payments}}<br/>    -->

                        </v-card>

                        <v-card class="pa-4" v-if="payment.autoPayment">
                            <v-card-title>정기 결제 후 수강권 추가</v-card-title>
                            <v-form v-model="valid">
                                <v-row>
                                    <v-col cols="6" class="pa-5">
                                        <v-list-item-title class="font-weight-bold text-decoration-underline">
                                            결제 금액 및 추가 수강권 횟수
                                        </v-list-item-title>
                                        <strong>액수:</strong> {{additionalPayRequestBody.numberOfMonths}} *
                                        {{payment.finalPrice/student.student.expectedLessonDuration}} =
                                        {{payment.finalPrice/student.student.expectedLessonDuration*(additionalPayRequestBody.numberOfMonths).replace(/\D/g,'')}}
                                        <br/>
                                        <strong>횟수:</strong> {{additionalPayRequestBody.numberOfMonths}} * 4주 *
                                        {{student.student.expectedNumberOfLessonsPerWeek}}회/week =
                                        총 {{(additionalPayRequestBody.numberOfMonths).replace(/\D/g,'') * 4 *
                                        student.student.expectedNumberOfLessonsPerWeek}}회
                                        <br/>
                                        <!--                                {{additionalPayRequestBody = student.student.expectedNumberOfLessonsPerWeek}}-->
                                        {{additionalPayRequestBody.totalClassToBeAdded}}

                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4" class="pa-5">
                                        <v-select
                                                :items="months"
                                                outlined
                                                v-model="additionalPayRequestBody.numberOfMonths"
                                                label="추가 개월"
                                                required
                                                :rules="[rules.required]"
                                                dense
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="4" class="pa-5">
                                        <v-text-field
                                                outlined
                                                v-model="additionalPayRequestBody.totalClassToBeAdded"
                                                label="총 횟수"
                                                :rules="[rules.required]"
                                                required
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="pa-5">
                                        <v-text-field
                                                outlined
                                                v-model="additionalPayRequestBody.payingAmount"
                                                label="액수"
                                                :rules="[rules.required]"
                                                required
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-btn
                                        :disabled="!valid"
                                        @click="manualStudentPayment(student.student.id, additionalPayRequestBody)">수강권
                                    추가하기
                                </v-btn>
                            </v-form>
                        </v-card>
                    </div>
                </v-card>
                <v-divider class="ma-2"></v-divider>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import UserService from "../../services/user.service"
    import StudentService from "../../services/student.service"
    // import info from "../../../info";

    export default {
        name: "StudentRenew",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedindex === -1 ? 'new item' : 'edit item'
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            // UserService.getAllUsers().then(
            //     (response) => {
            //         this.users = response.data;
            //     },
            //     (error) => {
            //         this.users =
            //             (error.response &&
            //                 error.response.data &&
            //                 error.response.data.message) ||
            //             error.message ||
            //             error.toString();
            //     }
            // );

            UserService.getAllUserStudents().then(
                (response) => {
                    this.userStudents = response.data;
                },
                (error) => {
                    this.userStudents =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            // add student info fetcher
            // StudentService.getStudentInfo(this.currentUser.id).then(
            //     (response) => {
            //         this.studentContent = response.data;
            //     },
            //     (error) => {
            //         this.studentContent =
            //             (error.response &&
            //                 error.response.data &&
            //                 error.response.data.message) ||
            //             error.message ||
            //             error.toString();
            //     }
            // );

            // StudentService.getAllStudents().then(
            //     (response) => {
            //         this.students = response.data;
            //     },
            //     (error) => {
            //         this.students =
            //             (error.response &&
            //                 error.response.data &&
            //                 error.response.data.message) ||
            //             error.message ||
            //             error.toString();
            //     }
            // );
            // if()
        },
        data: () => ({
            sumOfRemainingClasses: 0,
            rules: {
                required: (value) => !!value || '필수 입력',
            },
            valid: false,
            editedIndex: -1,
            search: '',
            userStudents: [],
            studentContent: "",
            requestBody: "",
            students: [],
            dialog: false,
            dialogDelete: false,
            users: [],
            additionalPayRequestBody: {
                numberOfMonths: "",
                totalClassToBeAdded: "",
                payingAmount: ""
            },
            headers: [
                {
                    text: 'user_id',
                    align: 'start',
                    value: 'id',
                },
                {text: 'username', value: 'username'},
                {text: 'firstname', value: 'firstName'},
                {text: 'email', value: 'email'},
                {text: 'phone number', value: 'phoneNumber'},
                {text: 'matching status', value: 'student.matchingStatus'},
                {text: 'register status', value: 'student.studentRegisterStatus'},
                {text: '수업', value: 'student.subjectsOfInterest'},
                {text: 'location', value: 'student.currentLocation'},
                {text: '시간', value: 'student.availableTimes'},
                {text: 'last login', value: 'lastLogin'},
                {text: 'Actions', value: 'actions', sortable: false},

            ],
            // headers: [
            //     {
            //         text: 'Dessert (100g serving)',
            //         align: 'start',
            //         sortable: false,
            //         value: 'name',
            //     },
            //     { text: 'Calories', value: 'calories' },
            //     { text: 'Fat (g)', value: 'fat' },
            //     { text: 'Carbs (g)', value: 'carbs' },
            //     { text: 'Protein (g)', value: 'protein' },
            //     { text: 'Actions', value: 'actions', sortable: false },
            // ],
            desserts: [],
            // editedIndex: -1,
            editedItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
            },
            defaultItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
            },
            months: [
                '1개월',
                '2개월',
                '3개월',
                '4개월',
                '5개월',
                '6개월',
                '7개월',
                '8개월',
                '9개월',
                '10개월',
                '11개월',
                '12개월'
            ],
            manualPaymentResponseBody: "",
        }),
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },

        created() {
            this.initialize()
        },
        methods: {

            updateUserForAdmin(userId, requestBody) {
                UserService.updateUserForAdmin(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        return response.data;
                    },
                    (error) => {
                        // this.users =
                        return (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            manualStudentPayment(userId, requestBody) {
                // requestBody.totalClassToBeAdded = requestBody.totalClassToBeAdded + remainingClasses
                StudentService.createManualStudentPayment(requestBody, userId).then(
                    (response) => {
                        this.manualPaymentResponseBody = response.data
                        // this.sendNotificationEmail({
                        //     'email': this.currentUser.email,
                        //     'emailBody': 'orderConfirmation'
                        // })
                        this.$router.go(0)
                    },
                    (error) => {
                        this.manualPaymentResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        // this.handleErrorMessages(this.teacherContent);
                    }
                )
            },


            initialize() {
            },

            editItem(user) {
                // console.log("================================");
                // console.log(user.id);
                // console.log('================================');
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                // updateUserForAdmin()
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm() {
                this.desserts.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save() {

                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)
                    this.updateUserForAdmin(this.editedItem.id, this.editedItem)
                } else {
                    this.users.push(this.editedItem)


                }
                this.close()
            },

            getUserInfoOfStudent(userId) {
                StudentService.getUserInfoOfStudent(userId).then(
                    (response) => {
                        console.log(response.data)
                        this.studentContent = response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        },
    }
</script>

<style scoped>

</style>
