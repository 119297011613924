<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    희망 과외 과목 <v-icon color="primary"> mdi-book-open-blank-variant</v-icon>
                    <v-divider class="mt-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    가르치고 싶은 과목을 모두 선택해 주세요. 세부 과목을 많이 선택해 주실수록 빠르고 원할한 매칭이 가능합니다!
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <v-form v-model="formValid">
<!--                    <v-card-title-->
<!--                            class="font-weight-bold mb-5"-->
<!--                            :class="{}">-->
<!--                        희망 과외 과목-->
<!--                    </v-card-title>-->
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                    class="select"
                                    v-model="teacherContent.subjectsOfExpertise"
                                    :items="mainSubjects"
                                    dense
                                    chips
                                    label="과목"
                                    multiple
                                    :rules="[rules.required, rules.subjectRequired]"
                                    auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllMainSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                >

                                </v-switch>
                            </v-select>
                            <div class="mb-5"></div>
                            <v-select v-if="teacherContent.subjectsOfExpertise && teacherContent.subjectsOfExpertise.includes('Mathematics / Computer Science')"
                                        :items="mathSubSubjects"
                                        class="select"
                                        v-model="teacherContent.selectedMathSubSubjects"
                                        @input="sortMathSubjectSelection"
                                        dense
                                        chips
                                        label="수학 세부과목"
                                        multiple
                                        :rules="[rules.mathSubSubjectRequired]"
                                        auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllMathSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                ></v-switch>
                            </v-select>
                            <v-select v-if="teacherContent.subjectsOfExpertise && teacherContent.subjectsOfExpertise.includes('Science')"
                                        :items="scienceSubSubjects"
                                        v-model="teacherContent.selectedScienceSubSubjects"
                                        class="select"
                                        dense
                                        chips
                                        @input="sortScienceSubjectSelection"
                                        label="과학 세부과목"
                                        multiple
                                        :rules="[rules.scienceSubSubjectRequired]"
                                        auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllScienceSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                ></v-switch>
                            </v-select>
                            <v-select v-if="teacherContent.subjectsOfExpertise && teacherContent.subjectsOfExpertise.includes('Reading / Writing')"
                                      :items="englishSubSubjects"
                                      class="select"
                                      v-model="teacherContent.selectedEnglishSubSubjects"
                                      @input="sortEnglishSubjectSelection"
                                      dense
                                      chips
                                      label="영어 세부과목"
                                      multiple
                                      :rules="[rules.englishSubSubjectRequired]"
                                      auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllEnglishSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                ></v-switch>
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">

                        수정하기
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                           :to="'/teacher/register/highschool'" text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                           :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>

                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "SubjectsOfExpertise",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            // sortedMathSubjects() {
            //     return this.
            // }

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        data() {
            return {
                teacherContent: "",
                formValid: false,
                undergrad: "",
                gradSchoolType: info.gradSchoolType,
                mainSubjects: info.mainSubjects,
                mathSubSubjects: info.mathSubSubjects,
                scienceSubSubjects: info.scienceSubSubjects,
                englishSubSubjects: info.englishSubSubjects,
                hasSelectedAllMainSubjects: false,
                hasSelectedAllMathSubjects: false,
                hasSelectedAllScienceSubjects: false,
                hasSelectedAllEnglishSubjects: false,


                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                    subjectRequired: (value) => (value.includes('Mathematics / Computer Science') || value.includes('Science')) || '필수 입력 값입니다.',
                    mathSubSubjectRequired: (value) => (value.includes("Math (Middle school)") ||
                        // value.includes("Elementary school") ||
                        value.includes("Algebra") ||
                        value.includes("Geometry") ||
                        value.includes("Pre-calculus") ||
                        value.includes("Calculus") ||
                        value.includes("AP Calc AB") ||
                        value.includes("AP Calc BC") ||
                        value.includes("Statistics") ||
                        value.includes("AP Statistics") ||
                        value.includes("Multi-variate Calculus") ||
                        value.includes("AP CS Principles") ||
                        value.includes("AP CS A") ||
                        value.includes("SAT")) || '필수 입력 값입니다.',
                    scienceSubSubjectRequired: (value) => (
                        // value.includes("Elementary school") ||
                        value.includes("Middle school") ||
                        value.includes("Biology") ||
                        value.includes("AP Biology") ||
                        value.includes("Chemistry") ||
                        value.includes("AP Chemistry") ||
                        value.includes("Environmental Science") ||
                        value.includes("AP Environmental Science") ||
                        value.includes("Astronomy") ||
                        value.includes("Physics") ||
                        value.includes("AP Physics 1") ||
                        value.includes("AP Physics 2") ||
                        value.includes("AP Physics C Mech") ||
                        value.includes("AP Physics C E&M")) || '필수 입력 값입니다.',
                    englishSubSubjectRequired: (value) => (
                        // value.includes("Elementary school") ||
                        value.includes("AP Literature") ||
                        value.includes("AP Language") ||
                        value.includes("Application Essay") ||
                        value.includes("High school") ||
                        value.includes("Middle school") ||
                        value.includes("Writing") ||
                        value.includes("Speech/Debate") ||
                        value.includes("SAT Reading/Writing")) || '필수 입력 값입니다.',

                },
                possibleHighSchoolTypes: info.possibleHighSchoolTypes
            }
        },
        methods: {
            sortScienceSubjectSelection() {

                // "AP Physics 1",
                //     "AP Physics 2",
                //     "AP Physics C Mech",
                //     "AP Physics C E&M",
                //     "Physics",
                //     "AP Chemistry",
                //     "Chemistry",
                //     "AP Biology",
                //     "Biology",
                //     "AP Environmental Science",
                //     "Astronomy",
                //     "Environmental Science",
                //     "Science (Middle School)",
                let listToReturn = [];
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Physics 1")) {
                    listToReturn[0] = "AP Physics 1";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Physics 2")) {
                    listToReturn[1] = "AP Physics 2";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Physics C Mech")) {
                    listToReturn[2] = "AP Physics C Mech";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Physics C E&M")) {
                    listToReturn[3] = "AP Physics C E&M";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("Physics")) {
                    listToReturn[4] = "Physics";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Chemistry")) {
                    listToReturn[5] = "AP Chemistry";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("Chemistry")) {
                    listToReturn[6] = "Chemistry";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Biology")) {
                    listToReturn[7] = "AP Biology";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("Biology")) {
                    listToReturn[8] = "Biology";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("AP Environmental Science")) {
                    listToReturn[9] = "AP Environmental Science";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("Astronomy")) {
                    listToReturn[10] = "Astronomy";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("Environmental Science")) {
                    listToReturn[11] = "Environmental Science";
                }
                if (this.teacherContent.selectedScienceSubSubjects &&
                    this.teacherContent.selectedScienceSubSubjects.includes("Science (Middle School)")) {
                    listToReturn[12] = "Science (Middle School)";
                }
                this.teacherContent.selectedScienceSubSubjects = listToReturn.filter(n => n);

            },

            sortMathSubjectSelection() {
                // "AP CS Principles",
                //     "AP CS A",
                //     "Programming",
                //     "Robotics",
                //     "Multi-variate Calculus",
                //     "AP Calc AB",
                //     "AP Calc BC",
                //     "Calculus",
                //     "Pre-calculus",
                //     "Algebra",
                //     "Geometry",
                //     "AP Statistics",
                //     "Statistics",
                //     "SAT",
                //     "Math (Middle School)",
                let listToReturn = [];
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("AP CS Principles")) {
                    listToReturn[0] = "AP CS Principles";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("AP CS A")) {
                    listToReturn[1] = "AP CS A";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("CS & Programming")) {
                    listToReturn[2] = "CS & Programming";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Robotics")) {
                    listToReturn[3] = "Robotics";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Multi-variate Calculus")) {
                    listToReturn[4] = "Multi-variate Calculus";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("AP Calc AB")) {
                    listToReturn[5] = "AP Calc AB";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("AP Calc BC")) {
                    listToReturn[6] = "AP Calc BC";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Calculus")) {
                    listToReturn[7] = "Calculus";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Pre-calculus")) {
                    listToReturn[8] = "Pre-calculus";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Algebra")) {
                    listToReturn[9] = "Algebra";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Geometry")) {
                    listToReturn[10] = "Geometry";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("AP Statistics")) {
                    listToReturn[11] = "AP Statistics";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Statistics")) {
                    listToReturn[12] = "Statistics";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("SAT")) {
                    listToReturn[13] = "SAT";
                }
                if (this.teacherContent.selectedMathSubSubjects &&
                    this.teacherContent.selectedMathSubSubjects.includes("Math (Middle School)")) {
                    listToReturn[14] = "Math (Middle School)";
                }
                this.teacherContent.selectedMathSubSubjects = listToReturn.filter(n => n);
            },

            sortEnglishSubjectSelection() {
                // "AP Literature",
                //     "AP Language",
                //     "Application Essay",
                //     "High school ",
                //     "Middle school",
                //     // "Elementary school",
                //     "Writing",
                //     "Speech/Debate",
                //     "SAT Reading/Writing"
                let listToReturn = [];
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("AP Literature")) {
                    listToReturn[0] = "AP Literature";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("AP Language")) {
                    listToReturn[1] = "AP Language";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("Application Essay")) {
                    listToReturn[2] = "Application Essay";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("High school")) {
                    listToReturn[3] = "High school";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("Middle school")) {
                    listToReturn[4] = "Middle school";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("Writing")) {
                    listToReturn[5] = "Writing";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("Speech/Debate")) {
                    listToReturn[6] = "Speech/Debate";
                }
                if (this.teacherContent.selectedEnglishSubSubjects &&
                    this.teacherContent.selectedEnglishSubSubjects.includes("SAT Reading/Writing")) {
                    listToReturn[7] = "SAT Reading/Writing";
                }

                this.teacherContent.selectedEnglishSubSubjects = listToReturn.filter(n => n);
            },

            selectAllMainSubjects(){
                this.hasSelectedAllMainSubjects = !this.hasSelectedAllMainSubjects;
                if (this.hasSelectedAllMainSubjects) {
                    this.teacherContent.subjectsOfExpertise = [...this.mainSubjects]
                } else if (!this.hasSelectedAllMainSubjects) {
                    this.teacherContent.subjectsOfExpertise = []
                }
            },
            selectAllMathSubjects() {
              this.hasSelectedAllMathSubjects = !this.hasSelectedAllMathSubjects;
              if (this.hasSelectedAllMathSubjects) {
                  this.teacherContent.selectedMathSubSubjects = [...this.mathSubSubjects]
              } else {
                  this.teacherContent.selectedMathSubSubjects = []
              }
            },
            selectAllScienceSubjects() {
                this.hasSelectedAllScienceSubjects = !this.hasSelectedAllScienceSubjects;
                if (this.hasSelectedAllScienceSubjects) {
                    this.teacherContent.selectedScienceSubSubjects = [...this.scienceSubSubjects]
                } else {
                    this.teacherContent.selectedScienceSubSubjects = []
                }
            },
            selectAllEnglishSubjects() {
                this.hasSelectedAllEnglishSubjects = !this.hasSelectedAllEnglishSubjects;
                if (this.hasSelectedAllEnglishSubjects) {
                    this.teacherContent.selectedEnglishSubSubjects = [...this.englishSubSubjects]
                } else {
                    this.teacherContent.selectedEnglishSubSubjects = []
                }
            },

            updateTeacherInfo(userId, requestBody) {

                TeacherService.updateTeacherSubjects(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/register/lesson/times")
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/lesson/times")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

    .select {
        max-width: 500px;
    }
</style>
