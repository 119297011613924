import axios from "axios";
import authHeader from "./auth-header";
import info from "../../info";

// const API_URL = info.urls.local + 'user/';
// const API_URL = 'https://backend-ie.herokuapp.com/api/user/';
// https://backend-ie.herokuapp.com/api/test/all

//prod
const API_URL = info.urls.production +'user/';


class UserService {

    getUserInfo(userId) {
        return axios.get(API_URL + userId, {headers: authHeader()});
    }

    getAllUsers() {
        return axios.get(API_URL, { headers: authHeader() });
    }

    getAllUserStudents() {
        return axios.get(API_URL + 'students', { headers: authHeader() });
    }

    getAllUserTeachers() {
        return axios.get(API_URL + 'teachers', { headers: authHeader() });
    }

    // getPublicContent() {
    //     return axios.get(API_URL + 'all');
    // }
    //
    // getUserBoard() {
    //     return axios.get(API_URL + 'user', { headers: authHeader() });
    // }
    // getTeacherBoard() {
    //     return axios.get(API_URL + 'teacher', { headers: authHeader() });
    // }
    //
    // getStudentBoard() {
    //     return axios.get(API_URL + 'student', { headers: authHeader() });
    // }
    //
    // getModeratorBoard() {
    //     return axios.get(API_URL + 'mod', { headers: authHeader() });
    // }
    //
    // getAdminBoard() {
    //     return axios.get(API_URL + 'admin', { headers: authHeader() });
    // }

    updateUserForAdmin(userId, requestBody) {
        return axios.put(API_URL + "admin/user/" + userId, requestBody, {headers: authHeader()});
    }

    updateUserInfo(userId, requestBody) {
        return axios.put(API_URL + userId, requestBody, {headers: authHeader()});
    }

    checkCurrentPassword(userId, password) {
        return axios.get(API_URL + "password/" +userId +"?password=" +password)
    }
}

export default new UserService();
