<template>
    <v-container>
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">
            <h2>
                <router-link to="/profile"> BACK TO PROFILE</router-link>
            </h2>
        </v-container>
        <v-container v-if="currentUser.roles[0] == 'ROLE_STUDENT'">
            <v-card
                    class="mx-auto primary--text"
                    max-width="750"
                    flat
                    :class="{}"
            >
                <v-card class=" mb-5 pb-5 rounded-lg" elevation="3">
                    <div class="py-3"></div>
                    <div class="main-highlight ml-5 my-10">학생 정보</div>
                    <div class="py-2"></div>
                    <div class="px-4" style="letter-spacing: -0px">
                        <strong style="font-size: 18px">학생분의 상세정보를 작성해주세요.</strong>
<!--                        <hr>-->
                        <div class="description-text" style="margin-top: 5px; font-size: 15px;">
                            학생분을 더 잘 이해하여 향 후 최적의 선생님을 매칭 하는 용도로 사용됩니다.
                        </div>
                    </div>
                </v-card>
                <v-divider class="mb-5"></v-divider>

                <v-card class="pb-4 pt-6 rounded-lg" >
                <template>

                    <v-form class="ml-5 mr-5 mt-5 mb-5"
                            ref="form"
                            v-model="valid"
                    >
                        <v-select
                                v-model="studentContent.currentLocation"
                                :items="countryItems"
                                :rules="[v => !!v || '필수 입력']"
                                label="현재 거주하시는 국가는 어디신가요?"
                                required
                        ></v-select>
                        <v-text-field v-if="studentContent.currentLocation == '직접입력'"
                                      v-model="currentLocation"
                                      label="거주국가"
                                      :rules="[v => !!v || '필수 입력']"
                                      required>
                        </v-text-field>
                        <div class="py-2"></div>
                        <v-select
                                v-model="studentContent.currentGrade"
                                :items="possibleGrades"
                                :rules="[v => !!v || '필수 입력']"
                                label="현재 학년"
                                required
                        ></v-select>
                        <div class="py-2"></div>
                        <v-select
                                v-model="studentContent.schoolType"
                                :items="possibleSchoolTypes"
                                :rules="[v => !!v || '필수 입력']"
                                label="재학중인 학교 종류"
                                required
                        ></v-select>
                        <div class="py-2"></div>
                        <v-text-field
                                v-model="studentContent.school"
                                label="학교 이름"
                                :rules="[v => !!v || '필수 입력']"
                                required>
                        </v-text-field>
                        <div class="py-2"></div>

                        <v-select
                                label="진학을 희망하는 전공 계열이 있나요? (선택)"
                                :items="desiredMajors"
                                v-model="studentContent.desiredMajor"
                                :rules="[rules.required]"

                        >
                        </v-select>
                        <div class="py-2"></div>

                        <v-text-field v-if="studentContent.desiredMajor == '기타'"
                                      v-model="desiredMajor"
                                      :rules="[v => !!v || '필수 입력']"

                                      label="희망 전공">
                        </v-text-field>
                        <!--                        {{studentContent}}-->

                        <v-select
                                v-model="studentContent.availableDays"
                                :items="preferredLessonDays"
                                @input="sortDayNames"
                                label="선호 수업 요일 및 시간대 (다중 선택 가능)"
                                multiple
                                chips
                                :rules="[rules.listNotEmpty]"

                        >
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllDays()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>
                        <!--                                                {{studentContent.detailedLessonTimes}}-->
                        <!--                                                {{studentContent}}-->
                        <!--                        df-->
                        <v-card elevation="0" class="ma-1 pa-1 text-subtitle-2"
                                v-show="studentContent.availableDays && studentContent.availableDays.length > 0">
                            <p>현재 거주하시는 국가 기준으로 작성하여 주세요.
                                <br/>
                                <br/>
                                선호 일정을 많이 선택해 주실수록 빠르고 원할한 매칭이 가능합니다.
                                <!--                                실제 진행 시간은 매칭된 선생님과 조율 후 진행됩니다.-->


                                <!--                                (ex. 화&목, 월&수&금) 선호 일정이며 실제 진행 시간은 매칭된 선생과 조율해서 진행하시면 됩니다.-->
                            </p>
                            <v-divider class="pa-1"></v-divider>
<!--                            <a target="_blank" href="https://savvytime.com/converter/kst-to-pst-mst-ct-et-akst-hst-sgt">-->
<!--                                Timezone 별 한국시간 검색하러가기 >-->
<!--                            </a>-->
                        </v-card>
                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('월')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.monday"
                                  dense
                                  filled
                                  chips
                                  label="월요일 세부 시간"
                                  @input="sortMondayTimes"
                                  multiple
                                  class="ma-3 "
                                  :rules="[rules.required, rules.listNotEmpty]"

                        >
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllMondayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('화')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.tuesday"
                                  dense
                                  filled
                                  chips
                                  label="화요일 세부 시간"
                                  @input="sortTuesdayTimes"
                                  multiple
                                  class="ma-3"
                                  :rules="[rules.required, rules.listNotEmpty]">
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllTuesdayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('수')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.wednesday"
                                  dense
                                  filled
                                  chips
                                  label="수요일 세부 시간"
                                  @input="sortWedTimes"
                                  multiple
                                  class="ma-3"
                                  :rules="[rules.required, rules.listNotEmpty]">
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllWednesdayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('목')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.thursday"
                                  dense
                                  filled
                                  chips
                                  label="목요일 세부 시간"
                                  @input="sortThursdayTimes"
                                  :rules="[rules.required, rules.listNotEmpty]"
                                  multiple
                                  class="ma-3 ">
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllThursdayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('금')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.friday"
                                  dense
                                  filled
                                  chips
                                  :rules="[rules.required, rules.listNotEmpty]"
                                  @input="sortFridayTimes"
                                  label="금요일 세부 시간"
                                  multiple
                                  class="ma-3 ">
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllFridayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('토')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.saturday"
                                  dense
                                  filled
                                  chips
                                  label="토요일 세부 시간"
                                  @input="sortSatTimes"
                                  :rules="[rules.required, rules.listNotEmpty]"

                                  multiple
                                  class="ma-3 ">
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllSaturdayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                        <v-select v-if="studentContent.availableDays && studentContent.availableDays.includes('일')"
                                  :items="preferredLessonTimeSlots"
                                  v-model="studentContent.detailedLessonTimes.sunday"
                                  dense
                                  filled
                                  chips
                                  label="일요일 세부 시간"
                                  @input="sortSundayTimes"
                                  :rules="[rules.required, rules.listNotEmpty]"
                                  multiple
                                  class="ma-3"
                        >
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllSundayTimes()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>
                        <div class="py-2"></div>

                        <v-select
                                v-model="studentContent.goalOfLesson"
                                :items="goalOfLessons"
                                label="과외 목적 (다중 선택 가능)"
                                :rules="[rules.required, rules.listNotEmpty]"

                                multiple
                                chips
                        ></v-select>
                        <div class="py-2"></div>

                        <v-select
                                v-model="studentContent.preferredLessonStyle"
                                :items="preferredLessonStyles"
                                label="선호 수업방식 (다중 선택 가능)"
                                :rules="[rules.required, rules.listNotEmpty]"

                                multiple
                                chips
                        ></v-select>
                        <div class="py-2"></div>

                        <v-select
                                v-model="studentContent.preferredLessonMaterial"
                                :items="preferredLessonMaterials"
                                label="선호 교재"
                                :rules="[rules.required, rules.listNotEmpty]"
                                multiple
                                chips
                        ></v-select>
                        <div class="py-2"></div>

                        <v-textarea
                                name="input-7-1"
                                filled
                                class="description-text"
                                label="수업 관련 추가 요청사항을 작성해주세요."
                                :rules="[v => !!v || '필수 입력']"

                                auto-grow
                                counter

                                placeholder="예) AP Computer Science 문제 풀이 위주로 수업하고 싶어요."
                                v-model="studentContent.lessonRemarks"
                        ></v-textarea>
                        <div class="py-2"></div>

                        <v-text-field
                                v-model="studentContent.kakaoId"
                                label="카카오톡 ID"
                                prepend-icon="$vuetify.icon.kakao"
                                :rules="[v => !!v || '필수 입력']"

                                required>
                        </v-text-field>
                        <div class="py-6"></div>

                        <div class="text-center">
                            <v-btn
                                    :disabled="!valid"
                                    color="secondary"
                                    x-large
                                    class="mr-4"
                                    @click="updateStudent(currentUser.id, studentContent)"
                            >
                                NEXT
                            </v-btn>
                        </div>
                    </v-form>
                </template>
                </v-card>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import StudentService from "../../../services/student.service"
    import EmailService from '../../../services/email.service'
    import info from "../../../../info";

    export default {
        name: "StudentMatchingInfoForm",
        components: {},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest);
                    this.studentContent.availableDays = this.removeEmptyStringFromList(this.studentContent.availableDays);
                    this.studentContent.availableTimes = this.removeEmptyStringFromList(this.studentContent.availableTimes);
                    this.studentContent.goalOfLesson = this.removeEmptyStringFromList(this.studentContent.goalOfLesson);
                    this.studentContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.studentContent.preferredLessonMaterial);
                    this.studentContent.preferredLessonStyle = this.removeEmptyStringFromList(this.studentContent.preferredLessonStyle);
                    this.studentContent.detailedLessonTimes = JSON.parse(this.studentContent.detailedLessonTimes)
                    // console.log("IN GET STUDENT INFO")
                    // console.log(this.studentContent)
                    // console.log("IN GET STUDENT INFO")
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {

            sortDayNames() {
                // let listToReturn = [];
                // studentContent.availableDays
                let listToReturn = [];
                if (this.studentContent.availableDays.includes('월')) {
                    listToReturn[0] = '월'
                }
                if (this.studentContent.availableDays.includes('화')) {
                    listToReturn[1] = '화'
                }
                if (this.studentContent.availableDays.includes('수')) {
                    listToReturn[2] = '수'
                }
                if (this.studentContent.availableDays.includes('목')) {
                    listToReturn[3] = '목'
                }
                if (this.studentContent.availableDays.includes('금')) {
                    listToReturn[4] = '금'
                }
                if (this.studentContent.availableDays.includes('토')) {
                    listToReturn[5] = '토'
                }
                if (this.studentContent.availableDays.includes('일')) {
                    listToReturn[6] = '일'
                }
                this.studentContent.availableDays = listToReturn.filter(n => n);
            },

            sortMondayTimes() {
                // studentContent.detailedLessonTimes.monday
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.monday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.monday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.monday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.monday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.monday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.monday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.monday = listToReturn.filter(n => n);
            },

            sortTuesdayTimes() {
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.tuesday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.tuesday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.tuesday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.tuesday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.tuesday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.tuesday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.tuesday = listToReturn.filter(n => n);
            },

            sortWedTimes() {
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.wednesday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.wednesday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.wednesday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.wednesday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.wednesday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.wednesday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.wednesday = listToReturn.filter(n => n);
            },

            sortThursdayTimes() {
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.thursday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.thursday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.thursday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.thursday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.thursday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.thursday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.thursday = listToReturn.filter(n => n);
            },
            sortFridayTimes() {
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.friday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.friday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.friday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.friday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.friday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.friday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.friday = listToReturn.filter(n => n);
            },
            sortSatTimes() {
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.saturday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.saturday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.saturday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.saturday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.saturday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.saturday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.saturday = listToReturn.filter(n => n);
            },
            sortSundayTimes() {
                let listToReturn = [];
                if (this.studentContent.detailedLessonTimes.sunday.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (this.studentContent.detailedLessonTimes.sunday.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (this.studentContent.detailedLessonTimes.sunday.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (this.studentContent.detailedLessonTimes.sunday.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (this.studentContent.detailedLessonTimes.sunday.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (this.studentContent.detailedLessonTimes.sunday.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                this.studentContent.detailedLessonTimes.sunday = listToReturn.filter(n => n);
            },

            isCurrentLocationNotInTheList() {
                // const currentLocation = this.studentContent.currentLocation;
                if (this.studentContent.currentLocation == '직접입력') {
                    this.currentLocationDifferent = true;
                    this.studentContent.currentLocation = '';

                    return true;
                }
                return false;
            },
            selectAllDays() {
                this.hasSelectedAllDays = !this.hasSelectedAllDays;
                if (this.hasSelectedAllDays) {
                    this.studentContent.availableDays = [...this.preferredLessonDays]
                } else if (!this.hasSelectedAllDays) {
                    this.studentContent.availableDays = []
                }
            },

            selectAllMondayTimes() {
                this.hasSelectedAllMondayTimes = !this.hasSelectedAllMondayTimes;
                if (this.hasSelectedAllMondayTimes) {
                    this.studentContent.detailedLessonTimes.monday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllMondayTimes) {
                    this.studentContent.detailedLessonTimes.monday = []
                }
            },

            selectAllTuesdayTimes() {
                this.hasSelectedAllTuesdayTimes = !this.hasSelectedAllTuesdayTimes;
                if (this.hasSelectedAllTuesdayTimes) {
                    this.studentContent.detailedLessonTimes.tuesday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllTuesdayTimes) {
                    this.studentContent.detailedLessonTimes.tuesday = []
                }
            },

            selectAllWednesdayTimes() {
                this.hasSelectedAllWednesdayTimes = !this.hasSelectedAllWednesdayTimes;
                if (this.hasSelectedAllWednesdayTimes) {
                    this.studentContent.detailedLessonTimes.wednesday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllWednesdayTimes) {
                    this.studentContent.detailedLessonTimes.wednesday = []
                }
            },

            selectAllThursdayTimes() {
                this.hasSelectedAllThursdayTimes = !this.hasSelectedAllThursdayTimes;
                if (this.hasSelectedAllThursdayTimes) {
                    this.studentContent.detailedLessonTimes.thursday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllThursdayTimes) {
                    this.studentContent.detailedLessonTimes.thursday = []
                }
            },

            selectAllFridayTimes() {
                this.hasSelectedAllFridayTimes = !this.hasSelectedAllFridayTimes;
                if (this.hasSelectedAllFridayTimes) {
                    this.studentContent.detailedLessonTimes.friday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllFridayTimes) {
                    this.studentContent.detailedLessonTimes.friday = []
                }
            },

            selectAllSaturdayTimes() {
                this.hasSelectedAllSaturdayTimes = !this.hasSelectedAllSaturdayTimes;
                if (this.hasSelectedAllSaturdayTimes) {
                    this.studentContent.detailedLessonTimes.saturday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllSaturdayTimes) {
                    this.studentContent.detailedLessonTimes.saturday = []
                }
            },

            selectAllSundayTimes() {
                this.hasSelectedAllSundayTimes = !this.hasSelectedAllSundayTimes;
                if (this.hasSelectedAllSundayTimes) {
                    this.studentContent.detailedLessonTimes.sunday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllSundayTimes) {
                    this.studentContent.detailedLessonTimes.sunday = []
                }
            },
            // validate() {
            //     this.$refs.form.validate()
            // },
            // reset() {
            //     this.$refs.form.reset()
            // },
            // resetValidation() {
            //     this.$refs.form.resetValidation()
            // },
            // applyStudentRegisterData(userId, requestBody) {
            //     // console.log(this.formContents);
            //     // this.$store.state.studentRegisterData = this.formContents;
            //     // console.log("====================");
            //     // console.log(this.$store.state.studentRegisterData);
            //
            //
            //
            // },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

            removeStringFromString(input) {
                return input.replace(/\D/g, '');
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeUnselectedDays() {
                if (!this.studentContent.availableDays.includes("월")) {
                    this.studentContent.detailedLessonTimes.monday = [];
                }
                if (!this.studentContent.availableDays.includes("화")) {
                    this.studentContent.detailedLessonTimes.tuesday = [];
                }
                if (!this.studentContent.availableDays.includes("수")) {
                    this.studentContent.detailedLessonTimes.wednesday = [];
                }
                if (!this.studentContent.availableDays.includes("목")) {
                    this.studentContent.detailedLessonTimes.thursday = [];
                }
                if (!this.studentContent.availableDays.includes("금")) {
                    this.studentContent.detailedLessonTimes.friday = [];
                }
                if (!this.studentContent.availableDays.includes("토")) {
                    this.studentContent.detailedLessonTimes.saturday = [];
                }
                if (!this.studentContent.availableDays.includes("일")) {
                    this.studentContent.detailedLessonTimes.sunday = [];
                }

            },

            updateStudent(userId, requestBody) {
                if (this.studentContent.currentLocation == '직접입력') {
                    this.studentContent.currentLocation = this.currentLocation;
                }

                if (this.studentContent.desiredMajor == '기타') {
                    this.studentContent.desiredMajor = this.desiredMajor;
                }

                this.$store.state.studentRegisterData = this.studentContent;
                // requestBody.expectedNumberOfLessonsPerWeek = this.removeStringFromString(requestBody.expectedNumberOfLessonsPerWeek);
                // requestBody.expectedLessonDuration = this.removeStringFromString(requestBody.expectedLessonDuration);
                requestBody.matchingStatus = 'MATCHING_INFO_FILLED';
                this.removeUnselectedDays();
                // console.log(this.studentContent.detailedLessonTimes)
                requestBody.availableDays = this.sortKoreanDayStrings(requestBody.availableDays);
                this.sortEachDays(requestBody.availableDays, requestBody.detailedLessonTimes)
                // requestBody.detailedLessonTimes = JSON.stringify(this.teacherContent.detailedLessonTimes);


                requestBody.detailedLessonTimes = JSON.stringify(this.studentContent.detailedLessonTimes);
                requestBody.availableDays = this.studentContent.availableDays;
                // console.log(requestBody.detailedLessonTimes)

                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        this.studentContent = response.data
                        // console.log(response.data);
                        this.$router.push("/enroll")

                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(error.response)
                    }
                )
            },
            sortKoreanDayStrings(listToSort) {
                let listToReturn = [];
                if (listToSort.includes('월')) {
                    listToReturn[0] = '월'
                }
                if (listToSort.includes('화')) {
                    listToReturn[1] = '화'
                }
                if (listToSort.includes('수')) {
                    listToReturn[2] = '수'
                }
                if (listToSort.includes('목')) {
                    listToReturn[3] = '목'
                }
                if (listToSort.includes('금')) {
                    listToReturn[4] = '금'
                }
                if (listToSort.includes('토')) {
                    listToReturn[5] = '토'
                }
                if (listToSort.includes('일')) {
                    listToReturn[6] = '일'
                }
                listToReturn = listToReturn.filter(n => n);
                return listToReturn
            },

            sortEachDays(availableDays, detailedLessonTimes) {

                // console.log("in sort each days")
                // console.log(detailedLessonTimes.monday)
                // console.log("in sort each days")
                if (availableDays.includes('월')) {
                    detailedLessonTimes.monday = this.sortDetailedLessonTimes(detailedLessonTimes.monday);
                }
                if (availableDays.includes('화')) {
                    detailedLessonTimes.tuesday = this.sortDetailedLessonTimes(detailedLessonTimes.tuesday);
                }
                if (availableDays.includes('수')) {
                    detailedLessonTimes.wednesday = this.sortDetailedLessonTimes(detailedLessonTimes.wednesday);
                }
                if (availableDays.includes('목')) {
                    detailedLessonTimes.thursday = this.sortDetailedLessonTimes(detailedLessonTimes.thursday);
                }
                if (availableDays.includes('금')) {
                    detailedLessonTimes.friday = this.sortDetailedLessonTimes(detailedLessonTimes.friday);
                }
                if (availableDays.includes('토')) {
                    detailedLessonTimes.saturday = this.sortDetailedLessonTimes(detailedLessonTimes.saturday);
                }
                if (availableDays.includes('일')) {
                    detailedLessonTimes.sunday = this.sortDetailedLessonTimes(detailedLessonTimes.sunday);
                }
            },

            sortDetailedLessonTimes(listToSort) {

                // "00~04시",
                //     "04~08시",
                //     "08~12시",
                //     "12~16시",
                //     "16~20시",
                //     "20~24시"

                // console.log("listToSort")
                // console.log(listToSort)
                // console.log("listToSort")
                let listToReturn = [];
                if (listToSort.includes('00~04시')) {
                    listToReturn[0] = '00~04시'
                }
                if (listToSort.includes("04~08시")) {
                    listToReturn[1] = "04~08시"
                }
                if (listToSort.includes('08~12시')) {
                    listToReturn[2] = '08~12시'
                }
                if (listToSort.includes('12~16시')) {
                    listToReturn[3] = '12~16시'
                }
                if (listToSort.includes('16~20시')) {
                    listToReturn[4] = '16~20시'
                }
                if (listToSort.includes('20~24시')) {
                    listToReturn[5] = '20~24시'
                }
                // console.log("listToReturn")
                // console.log(listToReturn)
                // console.log("listToReturn")
                listToReturn = listToReturn.filter(n => n);
                return listToReturn
            },

            moveToProfile() {
                // this.$store.dispatch('auth/logout');
                this.$router.push('/');
            }
        },
        data: () => (
            {
                rules: {
                    required: (value) => !!value || '필수 입력',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || '이메일을 올바르게 입력해주세요.';
                    },
                    listNotEmpty: (value) => !!value && value.length > 0 || '필수 선택'
                },
                desiredMajors: info.desiredMajors,
                formContents: {
                    subjects: [],
                    country: "",
                    frequency: "",
                    duration: "",
                    payableItem: [''],
                    agree: false,
                    tablet: "",

                },
                hasSelectedAllDays: false,
                hasSelectedAllMondayTimes: false,
                hasSelectedAllTuesdayTimes: false,
                hasSelectedAllWednesdayTimes: false,
                hasSelectedAllThursdayTimes: false,
                hasSelectedAllFridayTimes: false,
                hasSelectedAllSaturdayTimes: false,
                hasSelectedAllSundayTimes: false,
                temp: false,
                currentLocation: "",
                desiredMajor: "",
                currentLocationDifferent: false,
                items: info.teacherRegister.subjectsOfExpertise,
                studentContent: "",
                valid: false,
                name: '',
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 100) || 'must be less than 100 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                select: null,
                frequencyItems: [
                    '1회',
                    '2회',
                    '3회',
                    '5회',
                ],
                possibleSchoolTypes: [
                    '공립학교',
                    '사립학교',
                    '외국인/국제학교',
                    '외국어고등학교',
                    '과학고',
                    '홈스쿨링',
                    '직접입력',
                ],
                possibleGrades: [
                    '6학년',
                    '7학년',
                    '8학년',
                    '9학년',
                    '10학년',
                    '11학년',
                    '12학년',
                ],
                preferredLessonStyles: [
                    '문제/과제 풀이 위주',
                    // '시험 준비',
                    '개념 정리 위주',
                ],
                preferredLessonMaterials: [
                    '학생 소유 교재/과제 (학교 자료 등)',
                    '선생님이 준비하는 교재'
                ],
                goalOfLessons: [
                    '경시, 입시 시험 대비 (AP, IB, SAT2, etc.)',
                    '내신 관리 / 성적 향상'
                ],
                preferredLessonTimes: [
                    '오전',
                    '오후',
                    '저녁 이후'
                ],
                preferredLessonTimeSlots: [
                    "00~04시",
                    "04~08시",
                    "08~12시",
                    "12~16시",
                    "16~20시",
                    "20~24시"
                ],
                preferredLessonDays: [
                    '월',
                    '화',
                    '수',
                    '목',
                    '금',
                    '토',
                    '일',
                ],
                detailedLessonTimes: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: '',
                    saturday: '',
                    sunday: '',

                },

                countryItems: [
                    '대한민국',
                    '미국',
                    '호주',
                    '영국',
                    '캐나다',
                    '뉴질랜드',
                    '중국',
                    '일본',
                    '싱가포르',
                    '인도네시아',
                    '직접입력',
                ],
                durationItems: [
                    '1개월',
                    '2개월',
                    '3개월',
                    '6개월',
                    '12개월'
                ],
                tabletOptions: [
                    '줘',
                    '필요없음'
                ],
                checkbox: false,
                checkbox1: true,
                checkbox2: false,
            }
        )
    }
</script>

<style scoped>

    a:link {
        color: blue;
    }

    a:visited {
        color: purple;
    }

    .description-text {
        color: black;
        font-size: 15px;
    }

    .main-highlight {
        display: inline;
        padding: .25em 0;
        background: #A20916;
        color: #ffffff;
        box-shadow: .5em 0 0 #A20916, -.5em 0 0 #A20916;
        font-weight: bold;
        font-size: 17px;
        border-radius: 5px;
        letter-spacing: -1px !important;
    }
</style>
