<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    대학교 / 대학원
                    <v-divider class="mt-3 mb-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    Undergraduate / Graduate School
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <!-- 대학원 재학 이상 -->
                <v-form v-model="formValid" v-if="isBeyondUndergrad" class="black--text">
                    <v-card-title
                            :class="{}"
                            class="font-weight-bold">
                        대학교
                    </v-card-title>
                    <v-row class="mb-7">
                        <v-col cols="12"
                               sm="6">
                            <v-autocomplete
                                    v-model="teacherContent.undergrad"
                                    :items="schools"
                                    filled
                                    label="학교"
                                    :rules="[rules.required]"
                                    auto-select-first
                            ></v-autocomplete>
                        </v-col>
<!--                        <v-row >-->
                            <v-col v-if="teacherContent.undergrad == '직접 입력'"
                                    cols="12"
                                    sm="6"
                            >
                                <v-text-field
                                        class=""
                                        v-model="undergrad"
                                        filled
                                        label="학교"
                                        :rules="[rules.required]"
                                        placeholder="Ivy Ed University"
                                ></v-text-field>
                            </v-col>

<!--                        </v-row>-->

                        <v-col cols="12"
                               sm="6">
                            <v-text-field
                                    v-model="teacherContent.undergradMajor"
                                    filled
                                    label="전공"
                                    placeholder="ex) Economics"
                                    :rules="[rules.required]">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-title class="font-weight-bold">대학원</v-card-title>
                    <v-row class="mb-12">

                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-select
                                    v-model="teacherContent.gradSchoolType"
                                    :items="gradSchoolType"
                                    filled
                                    label="과정"
                                    :rules="[rules.required]"
                            ></v-select>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-autocomplete
                                    v-model="teacherContent.gradSchool"
                                    :items="schools"
                                    filled
                                    label="학교"
                                    :rules="[rules.required]"
                                    auto-select-first

                            ></v-autocomplete>
                        </v-col>
<!--                        <v-row >-->
                            <v-col v-if="teacherContent.gradSchool == '직접 입력'"
                                    cols="12"
                                    sm="6"
                            >
                                <v-text-field
                                        class=""
                                        v-model="gradSchool"
                                        filled
                                        label="학교"
                                        :rules="[rules.required]"
                                        placeholder="Ivy Ed University"
                                ></v-text-field>
                            </v-col>

<!--                        </v-row>-->

                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-text-field
                                    v-model="teacherContent.gradMajor"
                                    filled
                                    label="전공"
                                    :rules="[rules.required]"
                                    placeholder="ex) Economics"
                            >
                            </v-text-field>
                        </v-col>

                    </v-row>
                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)"
                            class="mr-3">
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        수정하기
                    </v-btn>

                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'" :to="'/teacher/register/finalDegree'"
                           text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                           :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>


                </v-form>

                <!-- 학부 이하 -->
                <v-form v-model="formValid" v-if="!isBeyondUndergrad">
                    <v-card-title class="font-weight-bold">대학교</v-card-title>
                    <v-row class="my-5">
                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-autocomplete
                                    v-model="teacherContent.undergrad"
                                    :items="schools"
                                    filled
                                    label="학교"
                                    :rules="[rules.required]"

                            ></v-autocomplete>
                        </v-col>
                        <v-col
                                v-if="teacherContent.undergrad == '직접 입력'"
                                cols="12"
                                sm="6"
                        >
                            <v-text-field
                                    class=""
                                    v-model="undergrad"
                                    filled
                                    label="학교"
                                    placeholder="Ivy Ed University"
                                    :rules="[rules.required]"

                            ></v-text-field>
                        </v-col>


                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-text-field
                                    v-model="teacherContent.undergradMajor"
                                    filled
                                    label="전공"
                                    placeholder="ex) Economics"
                                    :rules="[rules.required]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)"
                            class="mr-3"
                    >
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)"
                            class="mr-3">
                        수정하기
                    </v-btn>

                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'" :to="'/teacher/register/finalDegree'"
                           text
                    >
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                           :to="'/teacher/register/teacher/summary'"
                           text
                    >
                        Back
                    </v-btn>

                </v-form>

            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                teacherContent: "",
                schools: info.americanUniversities,
                formValid: false,
                undergrad: null,
                addSchool: false,
                gradSchoolType: info.gradSchoolType,
                isBeyondUndergrad: false,
                gradSchool: "",
                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                    listNotEmpty: (value) => value.length > 0 || '필수 선택',

                }
            }
        },
        methods: {
            updateTeacherInfo(userId, requestBody) {
                if (this.teacherContent.undergrad == '직접 입력') {
                    this.teacherContent.undergrad = this.undergrad;
                }

                if (this.teacherContent.gradSchool == '직접 입력') {
                    this.teacherContent.gradSchool = this.gradSchool;
                }
                const collegeRequestBody = {
                    undergrad: requestBody.undergrad,
                    undergradMajor: requestBody.undergradMajor,
                    gradSchool: requestBody.gradSchool,
                    gradSchoolType: requestBody.gradSchoolType,
                    gradMajor: requestBody.gradMajor,
                };


                TeacherService.updateTeacherSchools(userId, collegeRequestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/register/highschool")
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/highschool")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

</style>
