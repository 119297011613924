<template>
    <v-card
            class="mx-auto primary--text px-5 pb-5 ma-2"
            max-width="750"
            elevation="0"
    >
        <v-card-title class="mt-5">
            <!--                    <h1>상품 정보</h1>-->
            <span
                    :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
            ><strong>선생님 등록 정보</strong></span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"

        >
            <!--            <v-card-title>대학교 / 대학원</v-card-title>-->
            <v-row>
                <v-col md="10">
                    <p class="titles text-h6">
                        최종 학력
                    </p>
                </v-col>
                <v-col class="justify-right" md="2">
                    <v-btn
                            outlined
                            elevation="0"
                            rounded
                            text
                            to='/teacher/register/finalDegree'
                    >
                        수정하기
                    </v-btn>

                </v-col>
            </v-row>
            <p class="">
                {{teacherContent.highestLevelOfEducation}}
            </p>
        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"

        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles">
                        대학교 / 대학원
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn

                            elevation="0"
                            rounded
                            text
                            outlined
                            to="/teacher/register/college"
                    >
                        수정하기
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="6">
                    <p class="">
                        <span class="font-weight-bold">대학교</span>: {{teacherContent.undergrad}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">전공</span>: {{teacherContent.undergradMajor}}
                    </p>
                </v-col>
                <!--                {{teacherContent}}-->
                <v-col v-show="teacherContent.gradSchool != null" md="6">
                    <p class="">
                        <span class="font-weight-bold">대학원 과정</span>: {{teacherContent.gradSchoolType}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">대학원</span>: {{teacherContent.gradSchool}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">전공</span>: {{teacherContent.gradMajor}}
                    </p>
                </v-col>
            </v-row>
            <!--            {{teacherContent.gradSchool}}-->
            <!--            <v-divider v-show="teacherContent.gradSchool != ''" class="pt-2"></v-divider>-->
            <!--            <v-row v-show="teacherContent.gradSchool != ''">-->

            <!--                <v-col>-->
            <!--                    <p class="text&#45;&#45;primary">-->
            <!--                        <span class="font-weight-bold">대학원 과정</span>: {{teacherContent.gradSchoolType}}-->
            <!--                    </p>-->
            <!--                    <p class="text&#45;&#45;primary">-->
            <!--                        <span class="font-weight-bold">대학원</span>: {{teacherContent.gradSchool}}-->
            <!--                    </p>-->
            <!--                    <p class="text&#45;&#45;primary">-->
            <!--                        <span class="font-weight-bold">전공</span>: {{teacherContent.gradMajor}}-->
            <!--                    </p>-->
            <!--                </v-col>-->
            <!--            </v-row>-->
            <!--            <v-card-actions>-->
            <!--                <v-btn-->
            <!--                        elevation="0"-->
            <!--                        rounded-->
            <!--                        text-->
            <!--                        to="/teacher/register/college"-->
            <!--                >-->
            <!--                    수정하기-->
            <!--                </v-btn>-->
            <!--            </v-card-actions>-->

        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"

        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles">
                        고등학교
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn
                            outlined
                            rounded
                            text
                            to="/teacher/register/highschool"
                    >
                        수정하기
                    </v-btn>

                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="">
                        <span class="font-weight-bold">고등학교 형태</span>: {{teacherContent.highSchoolType}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">학교</span>: {{teacherContent.highSchool}}
                    </p>

                </v-col>
            </v-row>
        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"

        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles ">
                        희망 과외 과목
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn
                            outlined
                            rounded
                            text
                            to="/teacher/register/subjects"
                    >
                        수정하기
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <div v-if="teacherContent.subjectsOfExpertise && teacherContent.subjectsOfExpertise.includes('Mathematics / Computer Science')">
                        <p
                                class="font-weight-bold" style="font-size: 19px">
                            Mathematics / Computer Science
                        </p>
                        <li class="ml-5"
                                v-for="(item,i) in teacherContent.selectedMathSubSubjects"
                                :key="i">
                            {{item}}
                            <!--                        <span v-if="i != (teacherContent.selectedMathSubSubjects.length -1)">, </span>-->
                        </li>
                    </div>
                    <div class="mb-5"></div>
                    <div v-if="teacherContent.subjectsOfExpertise && teacherContent.subjectsOfExpertise.includes('Science')">
                        <p
                                class="font-weight-bold" style="font-size: 19px">
                            Science
                        </p>
                        <li
                                class="ml-5"
                                v-for="(item,i) in teacherContent.selectedScienceSubSubjects"
                                :key="i">
                            {{item}}
                            <!--                        <span v-if="i != (teacherContent.selectedMathSubSubjects.length -1)">, </span>-->
                        </li>
                    </div>
                    <div class="mb-5"></div>
                    <div v-if="teacherContent.subjectsOfExpertise && teacherContent.subjectsOfExpertise.includes('Reading / Writing')">
                        <p
                                class="font-weight-bold" style="font-size: 19px">
                            Reading / Writing
                        </p>
                        <li
                                class="ml-5"
                                v-for="(item,i) in teacherContent.selectedEnglishSubSubjects"
                                :key="i">
                            {{item}}
                            <!--                        <span v-if="i != (teacherContent.selectedMathSubSubjects.length -1)">, </span>-->
                        </li>
                    </div>
                </v-col>
            </v-row>

        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"

        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles">
                        희망 수업 요일 및 시간대
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn
                            elevation="0"
                            rounded
                            outlined
                            text
                            to="/teacher/register/lesson/times"
                    >
                        수정하기
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
<!--                    {{teacherContent.detailedLessonTimes}}-->

                    <div v-if="teacherContent.detailedLessonTimes && typeof teacherContent.detailedLessonTimes == 'string'">
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('월')">
                            <p class="">
                                <span class="font-weight-bold">월요일 세부시간</span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).monday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('화')">
                            <p class="">
                                <span class="font-weight-bold">화요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).tuesday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('수')">
                            <p class="">
                                <span class="font-weight-bold">수요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).wednesday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('목')">
                            <p class="">
                                <span class="font-weight-bold">목요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).thursday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('금')">
                            <p class="">
                                <span class="font-weight-bold">금요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).friday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('토')">
                            <p class="">
                                <span class="font-weight-bold">토요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).saturday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('일')">
                            <p class="">
                                <span class="font-weight-bold">일요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in JSON.parse(teacherContent.detailedLessonTimes).sunday" :key="i">
                                {{item}}
                            </li>
                        </div>
                    </div>
                    <div v-if="teacherContent.detailedLessonTimes && typeof teacherContent.detailedLessonTimes == 'object'">
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('월')">
                            <p class="">
                                <span class="font-weight-bold">월요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.monday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('화')">
                            <p class="">
                                <span class="font-weight-bold">화요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.tuesday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('수')">
                            <p class="">
                                <span class="font-weight-bold">수요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.wednesday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('목')">
                            <p class="">
                                <span class="font-weight-bold">목요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.thursday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('금')">
                            <p class="">
                                <span class="font-weight-bold">금요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.friday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('토')">
                            <p class="">
                                <span class="font-weight-bold">토요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.saturday" :key="i">
                                {{item}}
                            </li>
                        </div>
                        <div class="mb-3"
                             v-if="teacherContent.availableDays && teacherContent.availableDays.includes('일')">
                            <p class="">
                                <span class="font-weight-bold">일요일 세부시간 </span>
                            </p>
                            <li class="ml-5" v-for="(item, i) in teacherContent.detailedLessonTimes.sunday" :key="i">
                                {{item}}
                            </li>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-card-actions>

            </v-card-actions>

        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"

        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles">
                        희망 과외 기간
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn
                            elevation="0"
                            rounded
                            outlined
                            text
                            to="/teacher/register/lesson/duration"
                    >
                        수정하기
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="">
                        <span class="font-weight-bold">주당 과외 희망 시간</span>: {{teacherContent.demandingHoursPerWeek}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">총 과외 희망 기간</span>: {{teacherContent.demandingTotalDuration}}
                    </p>
                </v-col>
            </v-row>
            <v-card-actions>

            </v-card-actions>
        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"
        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles">
                        수업 스타일
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn
                            elevation="0"
                            rounded
                            outlined
                            text
                            to="/teacher/register/lecture/info"
                    >
                        수정하기
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="">
                        <span class="font-weight-bold">선호 수업방식</span>
                    </p>
                    <p class="text-subtitle-1">

                        <span v-for="(item, i) in teacherContent.preferredLessonStyle" :key="i">{{item}}<br/></span>
                        <span v-if="teacherContent.preferredLessonStyle && teacherContent.preferredLessonStyle.length == 0">선택 안함</span>
                    </p>

                    <p class="mt-8">
                        <span class="font-weight-bold">선호 교재</span>
                    </p>
                    <p class="text-subtitle-1">
                        <span v-for="(item, i) in teacherContent.preferredLessonMaterial" :key="i">{{item}}<br/></span>
                        <span v-if="teacherContent.preferredLessonStyle && teacherContent.preferredLessonMaterial.length == 0">선택 안함</span>

                    </p>
                </v-col>
            </v-row>
        </v-card>
        <v-divider></v-divider>

        <v-card
                elevation="0"
                max-width="750"
                class="mx-auto black--text pa-5 ma-2"
        >
            <v-row>
                <v-col md="10">
                    <p class="text-h6 titles">
                        기타 정보
                    </p>
                </v-col>
                <v-col md="2" class="justify-right">
                    <v-btn
                            elevation="0"
                            rounded
                            outlined
                            text
                            to="/teacher/register/teacher/info">
                        수정하기
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="">
                        <span class="font-weight-bold">거주 국가</span>: {{teacherContent.location}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">자기 소개</span>: {{teacherContent.personalIntro}}
                    </p>
                    <p class="">
                        <span class="font-weight-bold">카카오톡 ID</span>: {{teacherContent.kakaoId}}
                    </p>
                </v-col>
            </v-row>
        </v-card>

        <div align="" class="mt-8 text-center">
            <v-dialog
                    v-model="dialog"
                    width="500"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="secondary"
                            width="40%"
                    >

                        완료
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="accent--text text-subtitle-1  font-weight-bold primary lighten-2">
                        IVYED 선생님 등록
                    </v-card-title>

                    <v-card-text class="ml-1 pa-3 black--text font-weight-bold text-subtitle-2">
                        IVYED에 선생님으로 등록을 진행 하시겠습니까?
                        <br/>
                        <br/>
                        <p class="font-weight-medium">
                            등록 신청 후 영업일 3일내에 IVYED 운영팀에서 <br/> 서류 검토 후 연락 드리겠습니다.
                        </p>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn

                                color="primary"
                                class="font-weight-bold"
                                text
                                large
                                @click="submitTeacherInfo(currentUser.id, teacherContent)">

                            동의합니다
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

    </v-card>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import EmailService from '../../../services/email.service'

    export default {
        name: "TeacherSummary",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },
            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            submitTeacherInfo(userId, requestBody) {

                requestBody.detailedLessonTimes = requestBody.detailedLessonTimes+ ""
                requestBody.registerStatus = 'PENDING'
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.sendNotificationEmail({
                        //     "email": this.currentUser.email,
                        //     "emailBody": "[WIP] 선생님 등록이 완료 되었습니다. 우리가 3일안에 연락 주께 기둘"
                        // })
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(this.teacherContent)
                    }
                )
            },

        },
        data() {
            return {
                dialog: false,
                teacherContent: "",
                headers: [
                    {
                        text: '월',
                        // align: 'start',
                        // sortable: false,
                        value: 'monday',
                    },
                    {text: '화', value: 'tuesday'},
                    {text: '수', value: 'wednesday'},
                    {text: '목', value: 'thursday'},
                    {text: '금', value: 'friday'},
                    {text: '토', value: 'saturday'},
                    {text: '일', value: 'sunday'},
                ],
                scheduleItems: [
                    // {monday: this.teacherContent.detailedLessonTimes.monday}
                ]
            }

        }
    }
</script>

<style scoped>

    .titles {
        /*text-h6 font-weight-bold*/
        text-decoration: underline;
        font-weight: bold;
    }

    .justify-right {
        text-align: end;
    }
</style>
