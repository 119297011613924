import axios from "axios";
import authHeader from "./auth-header";
import info from "../../info";
// const API_URL = info.urls.local + 'lesson/class';
// const API_URL = 'https://backend-ie.herokuapp.com/api/email';
// https://backend-ie.herokuapp.com/api/test/all
//prod
const API_URL = info.urls.production +'lesson/class';

class ClassService {

    createClassForConfirmation(requestBody) {
        return axios.post(API_URL + "/admin", requestBody, {headers: authHeader()});
    }
    markAsPaid(classId) {
        return axios.put(API_URL +"/" + classId +"/pay", {headers: authHeader()});
    }
}

export default new ClassService();
