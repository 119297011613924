<template>
<!--<img src="../../assets/icons8-notion-240.png">-->
<img src="../../assets/notion-color.png">
</template>

<script>
    export default {
        name: "NotionIcon"
    }
</script>

<style scoped>

</style>
