<template>
    <div>
        <v-container v-if="getDefaultLanguage.includes('ko')" v-show="!loading" fluid class="pt-10"
                     :class="{
                                     // 'pa-0': $vuetify.breakpoint.mdAndDown,
                                     'login-style': $vuetify.breakpoint.mdAndUp,
                                     }"
        >
            <v-row no-gutters justify="center" class="mt-1">
                <!--            <v-col cols="auto" class="ma-0 pa-0">-->
                <v-col cols="auto" class="ma-0 pt-0">
                    <v-card color="accent" elevation="0"

                            clss="pa-5 pt-0 primary--text" shaped max-width="600px"
                            :class="{
                                     // 'pa-0': $vuetify.breakpoint.mdAndDown,
                                     'login-card': $vuetify.breakpoint.mdAndUp,
                                     }">
                        <v-form ref="loginform" v-model="valid">
                            <v-row no-gutters justify="center" align="center"
                                   class="text-h4 pt-15 font-weight-bold primary--text">
                                <v-col cols="12" class="text-center">
                                    <span class="ivy-part"> IVY</span><span class="ed-part">ED</span>
                                    <!--&lt;!&ndash;                                <img class="mr-3"&ndash;&gt;-->
                                    <!--                                <img-->
                                    <!--                                        class="hidden-sm-and-down"-->
                                    <!--                                     :src="require('../assets/IvyEd-cornell-color.png')" height="400"/>-->
                                    <!--                            </v-col>-->
                                    <!--                            <v-col cols="0" class="text-center hidden-md-and-up pa-15">-->
                                    <!--                                &lt;!&ndash;                                <span :style="{color: '#b71c1c'}">&ndash;&gt;-->
                                    <!--                                <img-->
                                    <!--                                        class="pa-0 ma-0"-->
                                    <!--                                        :src="require('../assets/IvyEd-cornell-color.png')" height="210"/>-->
                                    <!--&lt;!&ndash;                                로그인&ndash;&gt;-->

                                    <!--                                &lt;!&ndash;                            </span>&ndash;&gt;-->
                                </v-col>
                            </v-row>
                            <v-row no-gutters justify="center" align="center" class="px-5 pt-15 text-h4">
                                <v-col cols="9">
                                    <v-text-field
                                            outlined
                                            label="이메일"
                                            v-model="mainData.username"
                                            required
                                            :rules="[rules.required, rules.email]"
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                            outlined
                                            label="비밀번호"
                                            required
                                            v-model="mainData.password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required]"
                                            :type="showPassword ? 'text' : 'password'"
                                            @click:append="showPassword = !showPassword"
                                            dense
                                    ></v-text-field
                                    >
                                </v-col>
                            </v-row>
                            <v-row justify="center" align="center" class="px-5 mt-0 mb-5">
                                <v-col cols="9" class="text-center">
                                    <v-btn
                                            v-on:keyup.enter="handleLogin(mainData)"
                                            @click.prevent="handleLogin(mainData)"
                                            width="70%"
                                            color="secondary"
                                            type="submit"
                                            class="">
                                        로그인
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <div v-if="message">
                                <v-alert type="error">
                                    {{ message }}
                                </v-alert>
                            </div>


                            <v-row justify="center" align="center" no-gutters>
                                <v-col cols="12" class="text-center">
                                    <v-card color="" elevation="0" class="px-5 pb-5 border ma-0">
                                        <v-row no-gutters justify="center" class="mt-3">

                                            <a class="mr-3" href="/password/recover">
                                                아아디/비밀번호 찾기
                                            </a>
                                            <!--                                        <v-btn text>-->
                                            <!--                                            아아디/비밀번호 찾기-->
                                            <!--                                        </v-btn>-->
                                            <!--                                        |-->
                                            <!--                                        <a href="" class="ml-3 mr-3">-->
                                            <!--                                            학부모 로그인-->
                                            <!--                                        </a>-->
                                            |
                                            <a href="/register" class="ml-3 mr-3">
                                                회원가입
                                            </a>
                                            <!--                                        <v-col cols="12" class="text-center text-body-2">-->
                                            <!--                                            아직 가입하지 않으셨나요?</v-col-->
                                            <!--                                        >-->
                                            <!--                                        <v-col cols="9" class="mt-4 mb-3">-->
                                            <!--                                            <v-btn-->
                                            <!--                                                    to="/register"-->
                                            <!--                                                    width="40%"-->
                                            <!--                                                    rounded-->
                                            <!--                                                    outlined-->
                                            <!--                                                    color="primary"-->
                                            <!--                                            >회원가입</v-btn-->
                                            <!--                                            >-->
                                            <!--                                        </v-col>-->
                                        </v-row>
                                        <!--                                    <v-row no-gutters justify="center" class="mt-3 primary&#45;&#45;text">-->
                                        <!--                                        <v-col cols="12" class="text-center text-body-2">-->
                                        <!--                                            아이디 / 비밀번호를 잊으셨나요?</v-col-->
                                        <!--                                        >-->
                                        <!--                                        <v-col cols="9" class="mt-4 mb-3">-->
                                        <!--                                            <v-btn-->
                                        <!--                                                    to="/password/recover"-->
                                        <!--                                                    outlined-->
                                        <!--                                                    color="primary"-->
                                        <!--                                            >비밀번호 찾기</v-btn-->
                                        <!--                                            >-->
                                        <!--                                        </v-col>-->
                                        <!--                                    </v-row>-->
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="getDefaultLanguage.includes('en')" v-show="!loading" fluid class="pt-10"
                     :class="{
                                     // 'pa-0': $vuetify.breakpoint.mdAndDown,
                                     'login-style': $vuetify.breakpoint.mdAndUp,
                                     }"
        >
            <v-row no-gutters justify="center" class="mt-1">
                <!--            <v-col cols="auto" class="ma-0 pa-0">-->
                <v-col cols="auto" class="ma-0 pt-0">
                    <v-card color="accent" elevation="0"

                            clss="pa-5 pt-0 primary--text" shaped max-width="600px"
                            :class="{
                                     // 'pa-0': $vuetify.breakpoint.mdAndDown,
                                     'login-card': $vuetify.breakpoint.mdAndUp,
                                     }">
                        <v-form ref="loginform" v-model="valid">
                            <v-row no-gutters justify="center" align="center"
                                   class="text-h4 pt-15 font-weight-bold primary--text">
                                <v-col cols="12" class="text-center">
                                    <span class="ivy-part"> IVY</span><span class="ed-part">ED</span>
                                    <!--&lt;!&ndash;                                <img class="mr-3"&ndash;&gt;-->
                                    <!--                                <img-->
                                    <!--                                        class="hidden-sm-and-down"-->
                                    <!--                                     :src="require('../assets/IvyEd-cornell-color.png')" height="400"/>-->
                                    <!--                            </v-col>-->
                                    <!--                            <v-col cols="0" class="text-center hidden-md-and-up pa-15">-->
                                    <!--                                &lt;!&ndash;                                <span :style="{color: '#b71c1c'}">&ndash;&gt;-->
                                    <!--                                <img-->
                                    <!--                                        class="pa-0 ma-0"-->
                                    <!--                                        :src="require('../assets/IvyEd-cornell-color.png')" height="210"/>-->
                                    <!--&lt;!&ndash;                                로그인&ndash;&gt;-->

                                    <!--                                &lt;!&ndash;                            </span>&ndash;&gt;-->
                                </v-col>
                            </v-row>
                            <v-row no-gutters justify="center" align="center" class="px-5 pt-15 text-h4">
                                <v-col cols="9">
                                    <v-text-field
                                            outlined
                                            label="Email"
                                            v-model="mainData.username"
                                            required
                                            :rules="[rules.requiredEnglish, rules.emailEnglish]"
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                            outlined
                                            label="Password"
                                            required
                                            v-model="mainData.password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.requiredEnglish]"
                                            :type="showPassword ? 'text' : 'password'"
                                            @click:append="showPassword = !showPassword"
                                            dense
                                    ></v-text-field
                                    >
                                </v-col>
                            </v-row>
                            <v-row justify="center" align="center" class="px-5 mt-0 mb-5">
                                <v-col cols="9" class="text-center">
                                    <v-btn
                                            v-on:keyup.enter="handleLogin(mainData)"
                                            @click.prevent="handleLogin(mainData)"
                                            width="70%"
                                            color="secondary"
                                            type="submit"
                                            class="">
                                        Login
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <div v-if="message">
                                <v-alert type="error">
                                    {{ message }}
                                </v-alert>
                            </div>


                            <v-row justify="center" align="center" no-gutters>
                                <v-col cols="12" class="text-center">
                                    <v-card color="" elevation="0" class="px-5 pb-5 border ma-0">
                                        <v-row no-gutters justify="center" class="mt-3">

                                            <a class="mr-3" href="/password/recover">
                                                Forgot password?
                                            </a>
                                            <!--                                        <v-btn text>-->
                                            <!--                                            아아디/비밀번호 찾기-->
                                            <!--                                        </v-btn>-->
                                            <!--                                        |-->
                                            <!--                                        <a href="" class="ml-3 mr-3">-->
                                            <!--                                            학부모 로그인-->
                                            <!--                                        </a>-->
                                            |
                                            <a href="/register" class="ml-3 mr-3">
                                                Sign up
                                            </a>
                                            <!--                                        <v-col cols="12" class="text-center text-body-2">-->
                                            <!--                                            아직 가입하지 않으셨나요?</v-col-->
                                            <!--                                        >-->
                                            <!--                                        <v-col cols="9" class="mt-4 mb-3">-->
                                            <!--                                            <v-btn-->
                                            <!--                                                    to="/register"-->
                                            <!--                                                    width="40%"-->
                                            <!--                                                    rounded-->
                                            <!--                                                    outlined-->
                                            <!--                                                    color="primary"-->
                                            <!--                                            >회원가입</v-btn-->
                                            <!--                                            >-->
                                            <!--                                        </v-col>-->
                                        </v-row>
                                        <!--                                    <v-row no-gutters justify="center" class="mt-3 primary&#45;&#45;text">-->
                                        <!--                                        <v-col cols="12" class="text-center text-body-2">-->
                                        <!--                                            아이디 / 비밀번호를 잊으셨나요?</v-col-->
                                        <!--                                        >-->
                                        <!--                                        <v-col cols="9" class="mt-4 mb-3">-->
                                        <!--                                            <v-btn-->
                                        <!--                                                    to="/password/recover"-->
                                        <!--                                                    outlined-->
                                        <!--                                                    color="primary"-->
                                        <!--                                            >비밀번호 찾기</v-btn-->
                                        <!--                                            >-->
                                        <!--                                        </v-col>-->
                                        <!--                                    </v-row>-->
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!--        {{this.$store.state.enrollmentData}}-->
        <div v-show="loading" class="center">
            <v-progress-circular
                    :size="60"
                    :width="7"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
            <!--            <LoadingAnimation></LoadingAnimation>-->
        </div>

    </div>
</template>

<script>
    // import { Form, Field, ErrorMessage } from "vee-validate";
    import * as yup from "yup";
    // import LoadingAnimation from "./helpers/LoadingAnimation";

    export default {
        name: "Login",
        components: {
            // LoadingAnimation
            // Form,
            // Field,
            // ErrorMessage,
        },
        data() {
            const schema = yup.object().shape({
                username: yup.string().required("Username is required!"),
                password: yup.string().required("Password is required!"),
            });

            return {
                loading: false,
                message: "",
                schema,
                valid: false,
                rules: {
                    required: (value) => !!value || '필수 입력',
                    requiredEnglish: (value) => !!value || 'Required',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || '이메일을 올바르게 입력해주세요.';
                    },
                    emailEnglish: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Enter a valid email.';
                    },                },
                showPassword: false,
                mainData: {
                    username: '',
                    password: ''
                }
            };
        },
        computed: {
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            },
        },
        created() {
            if (this.loggedIn) {
                this.$router.push("/profile");
            }
        },
        methods: {
            handleErrorMessages(message) {
                // console.log(message)
                if (message.includes("Username")) {
                    this.message = "이미 사용중인 Username 입니다."
                } else if (message.includes("Email")) {
                    this.message = "이미 사용중인 이메일 주소입니다."
                } else if (message.includes("parse")) {
                    this.message = "위 필드를 모두 입력하셔야 가입이 가능합니다."
                } else if (message.includes("Bad")) {
                    this.message = "아이디 혹은 비밀번호가 일치하지 않습니다."
                } else if (message.includes("disabled")) {
                    this.message = "이메일 인증을 진행 해주세요."
                }
            },

            handleLogin(user) {
                this.loading = true;
                // console.log(user.username)
                // console.log(user)
                user.username = user.username.toLowerCase();
                // console.log((user.username))
                this.$store.dispatch("auth/login", user).then(
                    () => {
                        this.$router.push("/profile");
                    },
                    (error) => {
                        this.loading = false;
                        this.tempMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.handleErrorMessages(this.tempMessage);

                    }
                );
            },
        },
    };
</script>

<style scoped>
    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }

    label {
        display: block;
        margin-top: 10px;
    }

    .login-style {
        /*background-color: #FFF4FB*/
        /*background-color: #F4E1E3;*/
        /*background: url(../assets/background_option_001_blurred.png) center center/cover no-repeat;*/
        height: 1000px;
        /*calc(100vh - 50px);*/
        width: 100%;
    }

    .login-card {
        top: 30%;

    }
    .ivy-part {
        font-size: 50px;
    }
    .ed-part {
        font-size: 43px;
    }
</style>
