<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    고등학교 <v-icon color="primary">mdi-school</v-icon>
                    <v-divider class="mt-3 mb-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    가능한 비슷한 고등학교 출신의 학생으로 매칭이 진행됩니다.
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <v-form v-model="formValid">
<!--                    <v-card-title-->
<!--                            class="font-weight-bold mb-10"-->
<!--                            :class="{}">-->
<!--                        고등학교-->
<!--                    </v-card-title>-->
                    <v-row class="mb-8">
                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-select
                                    v-model="teacherContent.highSchoolType"
                                    :items="possibleHighSchoolTypes"
                                    :rules="[v => !!v || 'Item is required']"
                                    label="고등학교 형태"
                                    required
                            ></v-select>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                        >
                            <v-text-field
                                    v-model="teacherContent.highSchool"
                                    placeholder="Korea International School"
                                    clearable
                                    :rules="[v => !!v || 'Item is required']"
                                    label="이름"
                            ></v-text-field>

                        </v-col>
                    </v-row>

                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        수정하기
                    </v-btn>

                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'" :to="'/teacher/register/college'" text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'" :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>
                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            console.log(this.teacherContent)

        },
        data() {
            return {
                teacherContent: "",
                formValid: false,
                undergrad: "",
                gradSchoolType: info.gradSchoolType,

                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                },
                possibleHighSchoolTypes: info.possibleHighSchoolTypes
            }
        },
        methods: {
            updateTeacherInfo(userId, requestBody) {
                TeacherService.updateTeacherHighSchool(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/register/subjects")
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/subjects")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

</style>
