<template>
    <v-container fluid>
        <!--        {{userTeachers}}-->
        <v-card width="120%">
            <v-card-title>
                Teachers
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="userTeachers"
                    class="elevation-1"
                    :search="search"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >

                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialog"
                                max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    New Item
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <!--                                        {{editedItem}}-->
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.username"
                                                        label="username"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <!--                                            username: '',-->
                                                <!--                                            firstName: '',-->
                                                <!--                                            email: '',-->
                                                <!--                                            phoneNumber: '',-->
                                                <!--                                            roleType: '',-->
                                                <v-text-field
                                                        v-model="editedItem.firstName"
                                                        label="first name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.email"
                                                        label="Email"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.phoneNumber"
                                                        label="phone number"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-select
                                                        v-model="editedItem.teacher.registerStatus"
                                                        label="register status"
                                                        :items="teacherRegisterStatusItems"
                                                ></v-select>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.roleType"
                                                        label="role type"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                                v-model="dialogTeacherComment"
                                max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    New Item
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <!--                                        {{editedItem}}-->
                                        <v-row>
                                            <!--                                            <v-col-->
                                            <!--                                                    cols="12"-->
                                            <!--                                                    sm="6"-->
                                            <!--                                                    md="4"-->
                                            <!--                                            >-->
                                            <!--                                                <v-text-field-->
                                            <!--                                                        v-model="editedItem.username"-->
                                            <!--                                                        label="username"-->
                                            <!--                                                ></v-text-field>-->
                                            <!--                                            </v-col>-->
                                            <!--                                            <v-col-->
                                            <!--                                                    cols="12"-->
                                            <!--                                                    sm="6"-->
                                            <!--                                                    md="4"-->
                                            <!--                                            >-->
                                            <!--                                                &lt;!&ndash;                                            username: '',&ndash;&gt;-->
                                            <!--                                                &lt;!&ndash;                                            firstName: '',&ndash;&gt;-->
                                            <!--                                                &lt;!&ndash;                                            email: '',&ndash;&gt;-->
                                            <!--                                                &lt;!&ndash;                                            phoneNumber: '',&ndash;&gt;-->
                                            <!--                                                &lt;!&ndash;                                            roleType: '',&ndash;&gt;-->
                                            <!--                                                <v-text-field-->
                                            <!--                                                        v-model="editedItem.firstName"-->
                                            <!--                                                        label="first name"-->
                                            <!--                                                ></v-text-field>-->
                                            <!--                                            </v-col>-->
                                            <!--                                            <v-col-->
                                            <!--                                                    cols="12"-->
                                            <!--                                                    sm="6"-->
                                            <!--                                                    md="4"-->
                                            <!--                                            >-->
                                            <!--                                                <v-text-field-->
                                            <!--                                                        v-model="editedItem.email"-->
                                            <!--                                                        label="Email"-->
                                            <!--                                                ></v-text-field>-->
                                            <!--                                            </v-col>-->
                                            <!--                                            <v-col-->
                                            <!--                                                    cols="12"-->
                                            <!--                                                    sm="6"-->
                                            <!--                                                    md="4"-->
                                            <!--                                            >-->
                                            <!--                                                <v-text-field-->
                                            <!--                                                        v-model="editedItem.phoneNumber"-->
                                            <!--                                                        label="phone number"-->
                                            <!--                                                ></v-text-field>-->
                                            <!--                                            </v-col>-->
                                            <!--                                            <v-col-->
                                            <!--                                                    cols="12"-->
                                            <!--                                                    sm="6"-->
                                            <!--                                                    md="4"-->
                                            <!--                                            >-->
                                            <!--                                                <v-select-->
                                            <!--                                                        v-model="editedItem.teacher.registerStatus"-->
                                            <!--                                                        label="register status"-->
                                            <!--                                                        :items="teacherRegisterStatusItems"-->
                                            <!--                                                ></v-select>-->
                                            <!--                                            </v-col>-->
                                            <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                            >
                                                <v-textarea
                                                        v-model="editedItem.adminRemarks"
                                                        label="선생님 관련 커멘트"
                                                ></v-textarea>
                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveComment"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialogDelete" max-width="1200">
                            <v-card>
                                <v-card
                                        class="mx-auto primary--text px-5 ma-2"
                                        max-width="750"
                                        elevation="0"
                                >
                                    <v-card-title class="mt-5">
                                        <!--                    <h1>상품 정보</h1>-->
                                        <span
                                                :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                                        ><strong>선생님 등록 정보 조회</strong></span>
                                    </v-card-title>
                                    <v-card-subtitle>선생님 등록 정보를 변경하시려면 운영진에게 연락 주시면 변경을 도와드립니다.</v-card-subtitle>
                                    <v-divider></v-divider>
                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"

                                    >
                                        <!--            <v-card-title>대학교 / 대학원</v-card-title>-->
                                        <v-row>
                                            <v-col md="10">
                                                <p class="titles text-h6">
                                                    최종 학력
                                                </p>
                                            </v-col>
                                            <!--                <v-col md="2">-->
                                            <!--                    <v-btn-->
                                            <!--                            outlined-->
                                            <!--                            elevation="0"-->
                                            <!--                            rounded-->
                                            <!--                            color="primary"-->
                                            <!--                            text-->
                                            <!--                            to='/teacher/register/finalDegree'-->
                                            <!--                    >-->
                                            <!--                        수정하기-->
                                            <!--                    </v-btn>-->

                                            <!--                </v-col>-->
                                        </v-row>
                                        <p class="">
                                            {{teacherContent.highestLevelOfEducation}}
                                        </p>
                                    </v-card>
                                    <v-divider></v-divider>
                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"

                                    >
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles">
                                                    대학교 / 대학원
                                                </p>
                                            </v-col>
                                            <!--                <v-col md="2">-->
                                            <!--                    <v-btn-->
                                            <!--                            elevation="0"-->
                                            <!--                            rounded-->
                                            <!--                            text-->
                                            <!--                            outlined-->
                                            <!--                            color="primary"-->
                                            <!--                            to="/teacher/register/college"-->
                                            <!--                    >-->
                                            <!--                        수정하기-->
                                            <!--                    </v-btn>-->
                                            <!--                </v-col>-->
                                        </v-row>
                                        <v-row>
                                            <v-col md="6">
                                                <p class="">
                                                    <span class="font-weight-bold">대학교 </span>:
                                                    {{teacherContent.undergrad}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">전공 </span>:
                                                    {{teacherContent.undergradMajor}}
                                                </p>
                                            </v-col>
                                            <v-col v-show="teacherContent.gradSchool != ''" md="6">
                                                <p class="">
                                                    <span class="font-weight-bold">대학원 과정</span>:
                                                    {{teacherContent.gradSchoolType}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">대학원</span>:
                                                    {{teacherContent.gradSchool}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">전공</span>:
                                                    {{teacherContent.gradMajor}}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <!--            {{teacherContent.gradSchool}}-->
                                        <!--            <v-divider v-show="teacherContent.gradSchool != ''" class="pt-2"></v-divider>-->
                                        <!--            <v-row v-show="teacherContent.gradSchool != ''">-->

                                        <!--                <v-col>-->
                                        <!--                    <p class="text&#45;&#45;primary">-->
                                        <!--                        <span class="font-weight-bold">대학원 과정</span>: {{teacherContent.gradSchoolType}}-->
                                        <!--                    </p>-->
                                        <!--                    <p class="text&#45;&#45;primary">-->
                                        <!--                        <span class="font-weight-bold">대학원</span>: {{teacherContent.gradSchool}}-->
                                        <!--                    </p>-->
                                        <!--                    <p class="text&#45;&#45;primary">-->
                                        <!--                        <span class="font-weight-bold">전공</span>: {{teacherContent.gradMajor}}-->
                                        <!--                    </p>-->
                                        <!--                </v-col>-->
                                        <!--            </v-row>-->
                                        <!--            <v-card-actions>-->
                                        <!--                <v-btn-->
                                        <!--                        elevation="0"-->
                                        <!--                        rounded-->
                                        <!--                        text-->
                                        <!--                        to="/teacher/register/college"-->
                                        <!--                >-->
                                        <!--                    수정하기-->
                                        <!--                </v-btn>-->
                                        <!--            </v-card-actions>-->

                                    </v-card>
                                    <v-divider></v-divider>

                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"

                                    >
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles">
                                                    고등학교
                                                </p>
                                            </v-col>
                                            <!--                <v-col md="2">-->
                                            <!--                    <v-btn-->
                                            <!--                            outlined-->
                                            <!--                            rounded-->
                                            <!--                            text-->
                                            <!--                            to="/teacher/register/highschool"-->
                                            <!--                    >-->
                                            <!--                        수정하기-->
                                            <!--                    </v-btn>-->

                                            <!--                </v-col>-->
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p class="">
                                                    <span class="font-weight-bold">고등학교 형태</span>:
                                                    {{teacherContent.highSchoolType}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">학교 </span>:
                                                    {{teacherContent.highSchool}}
                                                </p>

                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-divider></v-divider>

                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"

                                    >
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles ">
                                                    희망 과외 과목
                                                </p>
                                            </v-col>
                                            <!--                <v-col md="2">-->
                                            <!--                    <v-btn-->
                                            <!--                            outlined-->
                                            <!--                            rounded-->
                                            <!--                            text-->
                                            <!--                            to="/teacher/register/subjects"-->
                                            <!--                    >-->
                                            <!--                        수정하기-->
                                            <!--                    </v-btn>-->
                                            <!--                </v-col>-->
                                        </v-row>

                                        <v-row>
                                            <v-col>
                                                <div>
                                                    <p
                                                            class="font-weight-bold" style="font-size: 17px">
                                                        Mathematics
                                                    </p>
                                                    <li
                                                            v-for="(item,i) in teacherContent.selectedMathSubSubjects"
                                                            :key="i">
                                                        {{item}}
                                                        <!--                        <span v-if="i != (teacherContent.selectedMathSubSubjects.length -1)">, </span>-->
                                                    </li>
                                                </div>
                                                <div>
                                                    <p
                                                            class="font-weight-bold" style="font-size: 17px">
                                                        Science
                                                    </p>
                                                    <li

                                                            v-for="(item,i) in teacherContent.selectedScienceSubSubjects"
                                                            :key="i">
                                                        {{item}}
                                                        <!--                        <span v-if="i != (teacherContent.selectedMathSubSubjects.length -1)">, </span>-->
                                                    </li>
                                                </div>
                                                <div>
                                                    <p
                                                            class="font-weight-bold" style="font-size: 17px">
                                                        English
                                                    </p>
                                                    <li

                                                            v-for="(item,i) in teacherContent.selectedEnglishSubSubjects"
                                                            :key="i">
                                                        {{item}}
                                                        <!--                        <span v-if="i != (teacherContent.selectedMathSubSubjects.length -1)">, </span>-->
                                                    </li>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card>
                                    <v-divider></v-divider>

                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"

                                    >
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles">
                                                    희망 수업 요일 및 시간대
                                                </p>
                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                {{teacherContent.availableDays}} <br/>
                                                <!--                                                {{teacherContent.detailedLessonTimes}}-->
                                                <!--                                                {{typeof teacherContent.detailedLessonTimes}}-->
                                                <strong>월</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).monday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.monday}}</span>
                                                <br/>
                                                <strong>화</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).tuesday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.tuesday}}</span>
                                                <br/>
                                                <strong>수</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).wednesday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.wednesday}}</span>
                                                <br/>
                                                <strong>목</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).thursday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.thursday}}</span>
                                                <br/>
                                                <strong>금</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).friday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.friday}}</span>
                                                <br/>
                                                <strong>토</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).saturday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.saturday}}</span>
                                                <br/>
                                                <strong>일</strong>&nbsp;&nbsp;
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'string'">{{JSON.parse(teacherContent.detailedLessonTimes).sunday}}</span>
                                                <span v-if="typeof teacherContent.detailedLessonTimes == 'object'">{{teacherContent.detailedLessonTimes.sunday}}</span>
                                                <br/>
                                            </v-col>
                                        </v-row>
                                        <v-card-actions>

                                        </v-card-actions>

                                    </v-card>
                                    <v-divider></v-divider>

                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"

                                    >
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles">
                                                    희망 과외 기간
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p class="">
                                                    <span class="font-weight-bold">주당 과외 희망 시간</span>:
                                                    {{teacherContent.demandingHoursPerWeek}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">총 과외 희망 기간 </span>:
                                                    {{teacherContent.demandingTotalDuration}}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-card-actions>

                                        </v-card-actions>
                                    </v-card>
                                    <v-divider></v-divider>

                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2">
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles">
                                                    수업 관련
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p class="">
                                                    <span class="font-weight-bold">선호 수업방식</span>
                                                </p>
                                                <p class="text-subtitle-1">

                                                    <span v-for="(item, i) in teacherContent.preferredLessonStyle"
                                                          :key="i">{{item}}</span>
                                                    <span>선택 안함</span>
                                                </p>

                                                <p class="mt-8">
                                                    <span class="font-weight-bold">선호 교재</span>
                                                </p>
                                                <p class="text-subtitle-1">
                                                    <span v-for="(item, i) in teacherContent.preferredLessonMaterial"
                                                          :key="i">{{item}}</span>
                                                    <span>선택 안함</span>

                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-divider></v-divider>

                                    <v-card
                                            elevation="0"
                                            max-width="750"
                                            class="mx-auto black--text pa-5 ma-2"
                                    >
                                        <v-row>
                                            <v-col md="10">
                                                <p class="text-h6 titles">
                                                    Teacher Information
                                                </p>
                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p class="">
                                                    <span class="font-weight-bold">거주 국가</span>:
                                                    {{teacherContent.location}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">자기 소개</span>:
                                                    {{teacherContent.personalIntro}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">카카오톡 ID</span>:
                                                    {{teacherContent.kakaoId}}
                                                </p>
                                                <p class="">
                                                    <span class="font-weight-bold">계좌정보</span>:
                                                    {{teacherContent.bankAccountType}}<br/>
                                                    {{teacherContent.bankName}}<br/>
                                                    {{teacherContent.bankAccountNumber}}<br/>

                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogSuggestStudent" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Suggest Student</span>
                                    <!--                            <h1>{{value}}</h1>-->
                                    <!--                            <v-divider></v-divider>-->
                                    <!--                            <h2>teacher ID: {{editedItem.id}}</h2>-->
                                    <!--                            <v-divider></v-divider>-->
                                    <!--                            <h2>student: {{studentsWithMatchingRequests}}</h2>-->
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <!--                                        <v-text-field-->
                                                <!--                                                v-model="editedItem.username"-->
                                                <!--                                                label="username"-->
                                                <!--                                        ></v-text-field>-->

                                                <v-autocomplete
                                                        v-model="value"
                                                        :items="studentsWithMatchingRequests"
                                                        item-text="studentId"
                                                        item-value="studentId"
                                                        return-object
                                                        dense
                                                        filled
                                                        label="Student ID"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-card>
                                            <v-card-title><h2>Lesson Info</h2></v-card-title>
                                            <h2 class="text--primary">
                                                Student ID: {{value.studentId}}
                                                <v-divider></v-divider>
                                                <strong>Student username: </strong>{{value.username}}
                                                <v-divider></v-divider>
                                                <strong>Payment ID:</strong> {{value.paymentId}}
                                                <v-divider></v-divider>
                                                <strong>Teacher ID:</strong> {{editedItem.id}}
                                            </h2>
                                        </v-card>

                                    </v-container>
                                </v-card-text>


                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeSuggestStudent"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="submitSuggestStudent"
                                    >
                                        Suggest Student
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                            small
                            class="mr-2"
                            @click="suggestStudent(item)"

                    >
                        mdi-account-plus
                    </v-icon>

                    <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                            small
                            @click="deleteItem(item)"
                    >
                        mdi-information
                    </v-icon>
                    <v-icon
                            small
                            @click="commentItem(item)"
                    >
                        mdi-comment
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn
                            color="primary"
                            @click="initialize"
                    >
                        Reset
                    </v-btn>
                </template>
            </v-data-table>
            <!--        {{userStudents}}-->
        </v-card>
        <v-divider class="mt-15 mb-15"></v-divider>
        <v-card width="100%">
            <!--            {{userTeachers}}-->
            <v-card-title>
                선생별 과외 리스트
            </v-card-title>
            <v-container fluid v-for="item in userTeachers" :key="item.id">
                <v-card-subtitle class="text-strong">
                    Teacher: {{item.username}}
                </v-card-subtitle>
                <v-divider></v-divider>
                <!--                {{item.teacher.lessons}}-->
                <v-data-table
                        :headers="lessonHeaders"
                        :items="item.teacher.lessons"
                        class="elevation-1"
                >
                </v-data-table>
            </v-container>
        </v-card>

    </v-container>
</template>

<script>
    import UserService from "../../services/user.service"
    import StudentService from "../../services/student.service"
    import LessonService from "../../services/lesson.service";
    import EmailService from '../../services/email.service'
    import TeacherService from "../../services/teacher.service"


    export default {
        name: "TeachersDataTable",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedIndex === -1 ? 'new item' : 'edit item'
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            UserService.getAllUsers().then(
                (response) => {
                    this.users = response.data;
                },
                (error) => {
                    this.users =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getAllUserStudents().then(
                (response) => {
                    this.userStudents = response.data;
                },
                (error) => {
                    this.userStudents =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getAllUserTeachers().then(
                (response) => {
                    this.userTeachers = response.data;
                    // console.log(this.userTeachers)
                },
                (error) => {
                    this.userTeachers =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            // add student info fetcher
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            StudentService.getAllStudents().then(
                (response) => {
                    this.students = response.data;
                },
                (error) => {
                    this.students =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            // if()
        },
        data: () => ({
            // editedIndex: -1,
            lessonRequestBody: {
                teacherId: '',
                studentId: '',
                paymentId: '',
            },
            lessonResponseBody: {},
            userStudents: [],
            studentsWithMatchingRequests: [],
            value: '',
            search: '',
            userTeachers: [],
            studentContent: "",
            requestBody: "",
            students: [],
            dialog: false,
            dialogDelete: false,
            dialogTeacherComment: false,
            dialogSuggestStudent: false,
            users: [],
            teacherRegisterStatusItems: [
                "NOT_STARTED",
                "STARTED",
                "REGISTER_INFO_FILLED",
                "PENDING",
                "PENDING_COFFEE_CHAT",
                "BEFORE_CONTRACT_SIGNING",
                "REGISTERED",
                "DEACTIVATED"
            ],
            headers: [

                {text: 'Actions', value: 'actions', sortable: false},
                {
                    text: 'user_id',
                    align: 'start',
                    value: 'id',
                },
                {text: 'email', value: 'email'},
                // {text: 'password', value: 'password'},
                // {text: 'email', value: 'email'},
                // {text: 'phone number', value: 'phoneNumber'},
                // {text: 'matching status', value: 'student.matchingStatus'},
                {text: 'register status', value: 'teacher.registerStatus'},
                {text: 'firstname', value: 'firstName'},
                {text: 'country', value: 'teacher.location'},
                // {text: 'country', value: 'location'},
                {text: 'phone#', value: 'phoneNumber'},
                {text: '카카오', value: 'teacher.kakaoId'},
                // 총 과외 paid + finished hours 합산 한 값
                // {text: 'total hours', value: 'teacher.availableTimes'},
                // number of unique lessons that aren't cancelled
                // # of lessons
                {text: '# of lessons', value: 'teacher.lessons.length'},
                {text: 'remarks', value: 'adminRemarks'},
                // {text: 'location', value: 'student.currentLocation'},
                {text: 'last login', value: 'lastLogin'},

            ],
            lessonHeaders: [
                {text: 'lesson_id', value: 'id', align: 'start',},
                {text: 'lesson_status', value: 'status'},
                {text: 'student_id', value: 'sid'},
                {text: 'pageCallLink', value: 'pageCallLink',},
                {text: 'reportLink', value: 'reportLink',},
                {text: 'teacherMatchingStatus', value: 'teacherMatchingStatus'},
                {text: 'studentMatchingStatus', value: 'studentMatchingStatus'},
                {text: 'schedule', value: 'schedule',},
                {text: 'message', value: 'message',},
            ],
            desserts: [],
            editedIndex: -1,
            editedItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
                teacher: '',
                adminRemarks: ''
            },
            defaultItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
                teacher: '',
            },
            teacherContent: "",
            sendEmail: false,
        }),
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
            dialogSuggestStudent(val) {
                val || this.closeSuggestStudent()
            }
        },

        created() {
            this.initialize()
        },
        methods: {
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },
            getTeacherInfo(userId) {
                TeacherService.getTeacherInfo(userId).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                        this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                        this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                        this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                        this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                        this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                        this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                        this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                        this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                        this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        // if (this.teacherContent == 'Access is denied') {
                        //     this.logOut();
                        // }
                    }
                );
            },
            getWaitingStudents() {


                for (let i = 0; i < this.userStudents.length; i++) {

                    if (this.userStudents[i].student.matchingStatus == 'REQUESTED') {
                        if (this.userStudents[i].student.lessons.length == 0) {
                            let temp = {
                                studentId: this.userStudents[i].id,
                                username: this.userStudents[i].username,
                                paymentId: this.userStudents[i].student.payments[this.userStudents[i].student.activePaymentId].id
                            };
                            this.studentsWithMatchingRequests.push(temp)
                        } else {
                            let cancelledLessonCount = 0;
                            for (let j = 0; j < this.userStudents[i].student.lessons.length; j++) {
                                if (this.userStudents[i].student.lessons[j].status == 'CANCELLED') {
                                    cancelledLessonCount += 1;
                                }
                            }
                            if (cancelledLessonCount == this.userStudents[i].student.lessons.length) {
                                let temp = {
                                    studentId: this.userStudents[i].id,
                                    username: this.userStudents[i].username,
                                    paymentId: this.userStudents[i].student.payments[this.userStudents[i].student.activePaymentId].id
                                };
                                this.studentsWithMatchingRequests.push(temp)
                            }

                        }


                    }
                }
            },

            findOutToWhomToEmailTo(user) {
                // console.log(user);
                // console.log(user);
                // console.log(user.roles[0]);
                if (user.roles[0].name == 'ROLE_TEACHER') {
                    // if (user.teacher.registerStatus ==  'REGISTER_INFO_FILLED') {
                    //
                    // } else if (user.teacher.registerStatus ==  'PENDING') {
                    //
                    // } else
                    if (user.teacher.registerStatus == 'PENDING_COFFEE_CHAT') {
                        this.sendNotificationEmail({
                            "email": user.email, "emailBody": "PENDING_COFFEE_CHAT"
                        })
                    }
                    // else if (user.teacher.registerStatus == 'BEFORE_CONTRACT_SIGNING') {
                    //     this.sendNotificationEmail({
                    //         "email": user.email, "emailBody": "BEFORE_CONTRACT_SIGNING"
                    //     })
                    // }
                    else if (user.teacher.registerStatus == 'REGISTERED') {
                        // console.log("============!=============")
                        this.sendNotificationEmail({
                            "email": user.email,
                            "emailBody": "TEACHER_REGISTERED"
                        })
                    }
                }
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                        this.$router.go(0)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            updateUserForAdmin(userId, requestBody) {
                UserService.updateUserForAdmin(userId, requestBody).then(
                    (response) => {
                        this.findOutToWhomToEmailTo(requestBody);
                        console.log(response.data);

                        return response.data;

                    },
                    (error) => {
                        // this.users =
                        return (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateCommentOnTeacherForAdmin(userId, requestBody) {
                UserService.updateUserForAdmin(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        return response.data;

                    },
                    (error) => {
                        // this.users =
                        return (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            initialize() {
            },

            suggestStudent(user) {
                console.log("====IN SUGGEST STUDENT====")
                console.log("====================================")
                console.log(this.users)

                this.editedIndex = this.users.indexOf(user);
                this.editedItem = Object.assign({}, user)

                console.log("====================================")
                console.log(this.editedIndex)
                console.log("====================================")

                this.getWaitingStudents();
                this.dialogSuggestStudent = true;
            },
            editItem(user) {
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                // console.log("====================================")
                console.log("====IN EDIT ITEM====")
                console.log(this.editedIndex)
                console.log("====================================")

                // updateUserForAdmin()
                this.dialog = true
            },

            deleteItem(user) {
                // this.editedIndex = this.desserts.indexOf(item)
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                console.log(this.editedItem.id)
                this.getTeacherInfo(this.editedItem.id)
                this.dialogDelete = true
            },

            commentItem(user) {
                // this.editedIndex = this.desserts.indexOf(item)
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                // console.log(this.editedItem.id)
                this.getTeacherInfo(this.editedItem.id)
                this.dialogTeacherComment = true
            },

            deleteItemConfirm() {
                this.desserts.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeTeacherComment() {
                this.dialogTeacherComment = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeSuggestStudent() {
                this.dialogSuggestStudent = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            submitSuggestStudent() {
                console.log(this.editedIndex);
                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)


                } else {

                    //call method here
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    // console.log(this.value)
                    // console.log(this.editedItem.id)
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    this.lessonRequestBody.teacherId = this.editedItem.id
                    this.lessonRequestBody.studentId = this.value.studentId
                    this.lessonRequestBody.paymentId = this.value.paymentId
                    this.createEmptyLesson(this.lessonRequestBody);
                    this.users.push(this.editedItem)
                }
                this.closeSuggestStudent()
            },

            save() {

                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)

                } else {
                    this.updateUserForAdmin(this.editedItem.id, this.editedItem)
                    this.users.push(this.editedItem)
                }
                this.close()
            },

            saveComment() {

                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)

                } else {
                    this.updateCommentOnTeacherForAdmin(this.editedItem.id, this.editedItem)
                    this.users.push(this.editedItem)
                }
                this.closeTeacherComment()
                this.$router.go(0)
            },

            getUserInfoOfStudent(userId) {
                StudentService.getUserInfoOfStudent(userId).then(
                    (response) => {
                        console.log(response.data)
                        this.studentContent = response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            createEmptyLesson(requestBody) {
                console.log("lesson creation started!");
                console.log(requestBody);
                // requestBody.teacherMatchingStatus = '';
                LessonService.createEmptyLesson(requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        console.log(requestBody);
                        this.sendNotificationEmail({
                            "teacherId": requestBody.teacherId + "",
                            "emailBody": "SUGGEST_STUDENT"
                        });
                        console.log(this.lessonResponseBody)
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        },

    }
</script>

<style scoped>

</style>
