<template>

    <div>
        <span class="ivy-part"> IVY</span><span class="ed-part">ED</span>
    </div>
</template>

<script>
    export default {
        name: "NavBarLogo"
    }
</script>

<style scoped>
    .ivy-part {
        color: #fffffb;
        font-size: 24px;
        font-weight: bold;
    }
    .ed-part {
        font-size: 20px;
        font-weight: bold;
        color: #fffffb;
    }
</style>
