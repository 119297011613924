<template>
    <img src="https://i.postimg.cc/sgZZ6cVG/blog.png">
</template>

<script>
    export default {
        name: "NaverBlogIcon"
    }
</script>

<style scoped>

</style>
