<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    최종 학력 <v-icon color="primary">mdi-school</v-icon>
                    <v-divider class="mt-3 mb-3"></v-divider>
                </span>
                <p class="black--text text-subtitle-2">
                    선생님의 최종 학력을 기입해주세요.
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 primary--text justify-center"
                    max-width="800"
                    elevation="0"
                    align="left"
            >
                <v-form v-model="formValid">
                    <v-row>
                        <v-col>
                            <v-select
                                    class="select "
                                    v-model="teacherContent.highestLevelOfEducation"
                                    :items="highestLevelOfEducation"
                                    filled
                                    label="최종학력"
                                    :rules="[v => !!v || 'Item is required']"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        수정하기
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'" :to="'/profile'" text>
                        Back
                    </v-btn>
                    <v-btn to="/teacher/register/teacher/summary"
                           v-show="teacherContent.registerStatus != 'NOT_STARTED'" text>
                        BACK
                    </v-btn>

                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)

                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                teacherContent: "",
                highestLevelOfEducation: info.teacherRegister.highestLevelOfEducation,
                formValid: false,
            }
        },
        methods: {
            updateTeacherInfo(userId, requestBody) {

                const finalDegreeRequestBody = {
                    highestLevelOfEducation: requestBody.highestLevelOfEducation
                }

                TeacherService.updateTeacherHighestLevelOfEducation(userId, finalDegreeRequestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/college")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .select {
        max-width: 500px;
    }
</style>
