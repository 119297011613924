<template>
    <v-card
            class="mx-auto mt-5 ml-5 mb-5 primary--text"
            max-width="750"
            outlined
    >
        <v-list-item three-line>
            <v-list-item-content class="mx-auto mt-5 ml-5 mb-5 primary--text"
                                 max-width="750">

                <h1>접근 권한이 없습니다. </h1>
                <v-divider class="mb-5 mt-5"></v-divider>
                <h2>

                </h2>
                <div
                        class="mx-auto mt-5 ml-5 mb-5 primary--text"
                        max-width="750"
                        outlined
                >
                    <v-btn
                            class="ma-2"
                            color="primary"
                            to="/"
                    >
                        홈으로
                        <v-icon
                                right
                                dark
                        >
                            mdi-home
                        </v-icon>
                    </v-btn>

                    <v-btn
                            color="primary"
                            class="ma-2 white--text"
                            to="/profile"
                    >
                        프로필 페이지
                        <v-icon
                                right
                                dark
                        >
                            mdi-account
                        </v-icon>
                    </v-btn>


                </div>


                <!--                        <v-list-item-subtitle>{{currentUser.email}}</v-list-item-subtitle>-->
            </v-list-item-content>

        </v-list-item>

        <!--        <v-card-actions>-->
        <!--            <v-btn-->
        <!--                    outlined-->
        <!--                    rounded-->
        <!--                    text-->
        <!--            >-->
        <!--                Button-->
        <!--            </v-btn>-->
        <!--        </v-card-actions>-->

    </v-card>

</template>

<script>
    export default {
        name: "RestrictedAcess"
    }
</script>

<style scoped>

</style>
