<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    비대면 과외 경험
                    <v-divider class="mt-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    IVYED는 100% 비대면 과외로 진행됩니다.
                    <br/>
                    <br/>
                    비대면 과외 경험이 없더라도 원활하게 수업하실 수 있도록 가이드 해드립니다!
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <!-- 대학원 재학 이상 -->
                <v-form v-model="formValid" >
<!--                    <v-card-subtitle-->
<!--                            :class="{}">-->
<!--                        비대면 과외 경험-->
<!--                    </v-card-subtitle>-->
                    <v-row class="pb-3">
                        <v-row>
                            <v-radio-group
                                    v-model="teacherContent.hasRemoteTutoringExperience"
                                    mandatory
                                    row
                                    class="ma-3 pa-3"
                            >
                                <v-radio
                                        label="경험이 있어요."
                                        value=true
                                ></v-radio>
                                <v-radio
                                        label="처음이에요."
                                        value=false
                                ></v-radio>
                            </v-radio-group>
                        </v-row>
                    </v-row>

                    <v-row v-if="teacherContent.hasRemoteTutoringExperience == 'true'">
                        <v-text-field
                                      v-model="teacherContent.remoteToolsUsed"
                                      label="비대면 과외시 사용했던 툴"
                                      placeholder="ex) Zoom, Skype, etc."
                                      class="mx-3"
                                      :rules="[rules.required]"

                        ></v-text-field>
                    </v-row>
                    <v-btn
                            class="mr-3"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'" :to="'/teacher/register/lesson/duration'" text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'" :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>

                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            console.log(this.teacherContent)

        },
        data() {
            return {
                teacherContent: "",
                formValid: false,
                undergrad: "",
                demandingHoursPerWeek: info.demandingHoursPerWeek,
                demandingTotalDuration: info.demandingTotalDuration,

                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                },
                possibleHighSchoolTypes: info.possibleHighSchoolTypes
            }
        },
        methods: {
            updateTeacherInfo(userId, requestBody) {

                TeacherService.updateTeacherLessonExperience(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/teacher/register/lecture/info")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

</style>
