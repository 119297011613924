<template>
    <v-container fluid class="container-width">
        <div class="py-2">
<!--            <v-icon>$vuetify.icons.coin</v-icon>-->
            <span style="font-size: 20px">💰</span>
            총 {{(totalToBePaid * 0.967).toLocaleString()}}원 보유중
        </div>
        <!--        <hr/>-->
        <!--        <div class="text-center py-4 semi-background">-->
        <!--            <v-btn width="150px" outlined elevation="0" class="mr-5"> 기프티콘</v-btn>-->
        <!--            <v-btn width="150px" outlined elevation="0"> 캐쉬아웃 신청</v-btn>-->
        <!--        </div>-->
        <!--        <hr/>-->
        <div class="my-2" v-for="item in this.teacherContent.lessons" :key="item.id">
<!--            {{item.classes}}-->
            <div v-if="item.classes && item.classes.length > 0">

                <v-card flat class="text-center sub-background pa-3">
                    학생 - {{item.studentName}}
                </v-card>

                <!-- FINISHED section -->
                <v-card flat v-for="eachClass in item.classes" :key="eachClass.id">
                    <!--                <v-card-subtitle>-->
                    <!--                </v-card-subtitle>-->
                    <div >
                        <v-card-subtitle>
                            <v-row>
                                <v-col md="4" class="text-left">
                                    <!--                            {{eachClass}}-->
                                    {{new Date(eachClass.classTimeAt.replace(/-/g, "/")).toLocaleDateString()}} <br/>
                                    과외 수업
<!--                                    {{new Date(eachClass.classTimeAt).toLocaleTimeString()}}-->
                                </v-col>
                                <v-col v-if="eachClass.totalAmountToBePaid != ''" md="8"
                                       class="text-right center-vertical font-weight-bold" style="color: #2024C2">
                                    + {{(eachClass.totalAmountToBePaid * 0.967).toLocaleString()}} 원
                                </v-col>

                            </v-row>
                            <hr/>
                        </v-card-subtitle>
                    </div>

                </v-card>

                <!-- PIAD section -->
<!--                <v-card-subtitle>-->
<!--                    PAID-->
<!--                </v-card-subtitle>-->
                <v-card flat v-for="eachClass in item.classes" :key="eachClass.id">
                    <!--                <v-card-subtitle>-->
                    <!--                </v-card-subtitle>-->
                    <div v-if="eachClass.status == 'PAID'">
                        <v-card-subtitle>
                            <v-row>
                                <v-col md="4" class="text-left">
                                    <!--                            {{eachClass}}-->
                                    {{new Date(eachClass.paidAt.replace(/-/g, "/")).toLocaleDateString()}} <br/>
                                    캐쉬 아웃
<!--                                    {{new Date(eachClass.classTimeAt).toLocaleTimeString()}}-->
                                </v-col>
                                <v-col v-if="eachClass.totalAmountToBePaid != ''" md="8"
                                       class="text-right center-vertical font-weight-bold red--text">
                                    - {{(eachClass.totalAmountToBePaid * 0.967).toLocaleString()}} 원
                                </v-col>

                            </v-row>
                            <hr/>
                        </v-card-subtitle>
                    </div>

                </v-card>
            </div>
        </div>

    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'

    export default {
        name: "TeacherBalance",
        data() {
            return {
                teacherContent: "",
                classes: [],
                totalToBePaid: 0,
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },
        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)

                    for (let i = 0; i < this.teacherContent.lessons.length; i++) {
                        for (let j = 0; j < this.teacherContent.lessons[i].classes.length; j++) {
                            if (this.teacherContent.lessons[i].classes[j].status == 'FINISHED') {
                                this.totalToBePaid += this.teacherContent.lessons[i].classes[j].totalAmountToBePaid
                            }
                        }
                        // this.classes[this.teacherContent.lessons[i].id] = this.teacherContent.lessons[i].classes;
                    }


                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    }
</script>

<style scoped>

    .semi-background {
        background-color: #F0F0F0;
    }

    .sub-background {
        background-color: #E7EEF2;
        font-size: 14px;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .container-width {
        max-width: 900px;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
