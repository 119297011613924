<template>
    <div v-if="currentUser.roles[0] == 'ROLE_ADMIN'"
         class="container-background">
        {{actionRequiredLessons}}
        <v-container fluid v-for="teacher in userTeachers" :key="teacher.id" class="">

            <v-card

                    class="mx-auto teacher-card"
                    max-width=""
                    outlined
                    elevation="0"
                    color=""
            >
                <div class="teacher-info ma-3">
                    이름: {{teacher.lastName}}{{teacher.firstName}}<br/>
                    이메일: {{teacher.email}}<br/>
                    전화번호: {{teacher.countryCode}} {{teacher.phoneNumber}}<br/>
                    최근 로그인: {{teacher.lastLogin}}<br/>
                </div>
                <div v-for="lesson in getNotStartedOnly(teacher.teacher.lessons)" :key="lesson.lesson_id">
                    <v-expansion-panels class="py-1">
                        <v-expansion-panel class="panel">
                            <div v-if="lesson.status == 'NOT_STARTED'">
                                <v-card-title class=""> NOT_STARTED - 선생한테만 추천이 간 상태
                                </v-card-title>
                                <v-expansion-panel-header>
                                    과외번호: {{lesson.id}}, 학생번호: {{lesson.sid}}, 학생 이름: {{lesson.studentName}}, 학생 이메일:
                                    {{lesson.studentEmail}}, 학생 연락처: {{lesson.studentContact}}
                                    <br/>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 정보</v-card-title>
                                                <p class="font-weight-bold text-h6"> 학생 이름: <span class="blue--text">{{lesson.studentName}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 이메일: <span class="blue--text">{{lesson.studentEmail}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 연락처: <span class="blue--text">{{lesson.studentContact}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson id: <span
                                                        class="blue--text">{{lesson.id}} </span></p>
                                                <p class="font-weight-bold text-h6"> schedule: <span class="blue--text">{{lesson.scheduledAt}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson status: <span
                                                        class="blue--text">{{lesson.status}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher matching status: <span
                                                        class="blue--text">{{lesson.teacherMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student matching status: <span
                                                        class="blue--text">{{lesson.studentMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student id: <span
                                                        class="blue--text">{{lesson.sid}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher id: <span
                                                        class="blue--text">{{lesson.tid}} </span></p>
                                                <p class="font-weight-bold text-h6"> 과외 취소 이유: <span class="blue--text">{{lesson.adminRemarks}} </span>
                                                </p>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <!--                    {{lesson}}-->
                                                <v-card-title>페이지콜 (화이트보드) 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> page call link: <br/>(http:// or
                                                    https:// prefix 필수입력 ) <span class="blue--text"> {{lesson.pageCallLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="Page Call 링크"
                                                            v-model="whiteboardRequestBody.pageCallLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateWhiteboardLink(lesson.id, whiteboardRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업일지 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> 수입일지 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.reportLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="feedbackReportRequestBody.reportLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateFeedbackReportLink(lesson.id, feedbackReportRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업영상 링크 업데이트</v-card-title>
                                                <!-- 수업영상 링크는 lesson 객체내에 안쓰는 컬럼인 schedule로 일단 사용 -->
                                                <p class="font-weight-bold text-h6"> 영상 GDrive 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.schedule}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="videoLinkRequestBody.linkToVideo"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateLinkToVideo(lesson.id, videoLinkRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col v-if="lesson.status =='BOTH_ACCEPTED'">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 상태 -> 예정 중으로 바꾸기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form>
                                                    <v-btn x-large color=""
                                                           @click="updateLessonToScheduled(lesson.id)"> 업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 취소 하기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form v-model="cancelValidation">
                                                    <v-textarea
                                                            outlined
                                                            label="비고 - 나중을 위해 꼭 기록하세요."
                                                            :rules="[rules.required]"
                                                            v-model="cancelLessonRequestBody.adminRemarks"
                                                            dense
                                                    ></v-textarea>
                                                    <v-btn x-large color=""
                                                           :disabled="!cancelValidation"
                                                           @click="updateLessonToCancelled(lesson.id, cancelLessonRequestBody)">
                                                        과외 취소하기
                                                    </v-btn>
                                                </v-form>

                                                <!--                                        <v-form>-->
                                                <!--                                            <v-btn x-large color=""-->
                                                <!--                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </v-form>-->
                                            </v-card>
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </div>
                        </v-expansion-panel>
                        <br/>
                        <br/>
                    </v-expansion-panels>
                </div>

                <div v-for="lesson in getTeacherAcceptedOnly(teacher.teacher.lessons)" :key="lesson.lesson_id">
                    <v-expansion-panels class="py-1">
                        <v-expansion-panel class="panel">

                            <div v-if="lesson.status == 'TEACHER_ACCEPTED'">
                                <v-card-title class="" v-if="lesson.status == 'TEACHER_ACCEPTED'"> [선생이 수락한 상태]
                                    {{lesson.status}}
                                </v-card-title>

                                <!--                            <v-card-title class="action-required" > [카톡 등 우리가 액션 필요한 과외] - BOTH_ACCEPTED</v-card-title>-->
                                <v-expansion-panel-header>
                                    과외번호: {{lesson.id}}, 학생번호: {{lesson.sid}}, 학생 이름: {{lesson.studentName}}, 학생 이메일:
                                    {{lesson.studentEmail}}, 학생 연락처: {{lesson.studentContact}}
                                    <br/>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 정보</v-card-title>
                                                <p class="font-weight-bold text-h6"> 학생 이름: <span class="blue--text">{{lesson.studentName}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 이메일: <span class="blue--text">{{lesson.studentEmail}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 연락처: <span class="blue--text">{{lesson.studentContact}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson id: <span
                                                        class="blue--text">{{lesson.id}} </span></p>
                                                <p class="font-weight-bold text-h6"> schedule: <span class="blue--text">{{lesson.scheduledAt}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson status: <span
                                                        class="blue--text">{{lesson.status}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher matching status: <span
                                                        class="blue--text">{{lesson.teacherMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student matching status: <span
                                                        class="blue--text">{{lesson.studentMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student id: <span
                                                        class="blue--text">{{lesson.sid}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher id: <span
                                                        class="blue--text">{{lesson.tid}} </span></p>
                                                <p class="font-weight-bold text-h6"> 과외 취소 이유: <span class="blue--text">{{lesson.adminRemarks}} </span>
                                                </p>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <!--                    {{lesson}}-->
                                                <v-card-title>페이지콜 (화이트보드) 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> page call link: <br/>(http:// or
                                                    https:// prefix 필수입력 ) <span class="blue--text"> {{lesson.pageCallLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="Page Call 링크"
                                                            v-model="whiteboardRequestBody.pageCallLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateWhiteboardLink(lesson.id, whiteboardRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업일지 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> 수입일지 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.reportLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="feedbackReportRequestBody.reportLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateFeedbackReportLink(lesson.id, feedbackReportRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col v-if="lesson.status =='BOTH_ACCEPTED'">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 상태 -> 예정 중으로 바꾸기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form>
                                                    <v-btn x-large color=""
                                                           @click="updateLessonToScheduled(lesson.id)"> 업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업영상 링크 업데이트</v-card-title>
                                                <!-- 수업영상 링크는 lesson 객체내에 안쓰는 컬럼인 schedule로 일단 사용 -->
                                                <p class="font-weight-bold text-h6"> 영상 GDrive 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.schedule}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="videoLinkRequestBody.linkToVideo"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateLinkToVideo(lesson.id, videoLinkRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 취소 하기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form v-model="cancelValidation">
                                                    <v-textarea
                                                            outlined
                                                            label="비고 - 나중을 위해 꼭 기록하세요."
                                                            :rules="[rules.required]"
                                                            v-model="cancelLessonRequestBody.adminRemarks"
                                                            dense
                                                    ></v-textarea>
                                                    <v-btn x-large color=""
                                                           :disabled="!cancelValidation"
                                                           @click="updateLessonToCancelled(lesson.id, cancelLessonRequestBody)">
                                                        과외 취소하기
                                                    </v-btn>
                                                </v-form>

                                                <!--                                        <v-form>-->
                                                <!--                                            <v-btn x-large color=""-->
                                                <!--                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </v-form>-->
                                            </v-card>
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </div>

                        </v-expansion-panel>
                        <br/>
                        <br/>
                    </v-expansion-panels>
                </div>


                <div v-for="lesson in getBothAcceptedOnly(teacher.teacher.lessons)" :key="lesson.lesson_id">
                    <v-expansion-panels class="py-1">
                        <v-expansion-panel class="panel">
                            <div v-if="lesson.status == 'BOTH_ACCEPTED'">
                                <v-card-title class="action-required"> [카톡 등 우리가 액션 필요한 과외] - BOTH_ACCEPTED
                                </v-card-title>
                                <v-expansion-panel-header>
                                    과외번호: {{lesson.id}}, 학생번호: {{lesson.sid}}, 학생 이름: {{lesson.studentName}}, 학생 이메일:
                                    {{lesson.studentEmail}}, 학생 연락처: {{lesson.studentContact}}
                                    <br/>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 정보</v-card-title>
                                                <p class="font-weight-bold text-h6"> 학생 이름: <span class="blue--text">{{lesson.studentName}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 이메일: <span class="blue--text">{{lesson.studentEmail}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 연락처: <span class="blue--text">{{lesson.studentContact}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson id: <span
                                                        class="blue--text">{{lesson.id}} </span></p>
                                                <p class="font-weight-bold text-h6"> schedule: <span class="blue--text">{{lesson.scheduledAt}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson status: <span
                                                        class="blue--text">{{lesson.status}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher matching status: <span
                                                        class="blue--text">{{lesson.teacherMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student matching status: <span
                                                        class="blue--text">{{lesson.studentMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student id: <span
                                                        class="blue--text">{{lesson.sid}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher id: <span
                                                        class="blue--text">{{lesson.tid}} </span></p>
                                                <p class="font-weight-bold text-h6"> 과외 취소 이유: <span class="blue--text">{{lesson.adminRemarks}} </span>
                                                </p>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <!--                    {{lesson}}-->
                                                <v-card-title>페이지콜 (화이트보드) 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> page call link: <br/>(http:// or
                                                    https:// prefix 필수입력 ) <span class="blue--text"> {{lesson.pageCallLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="Page Call 링크"
                                                            v-model="whiteboardRequestBody.pageCallLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateWhiteboardLink(lesson.id, whiteboardRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업일지 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> 수입일지 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.reportLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="feedbackReportRequestBody.reportLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateFeedbackReportLink(lesson.id, feedbackReportRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업영상 링크 업데이트</v-card-title>
                                                <!-- 수업영상 링크는 lesson 객체내에 안쓰는 컬럼인 schedule로 일단 사용 -->
                                                <p class="font-weight-bold text-h6"> 영상 GDrive 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.schedule}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="videoLinkRequestBody.linkToVideo"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateLinkToVideo(lesson.id, videoLinkRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>

                                        <v-col v-if="lesson.status =='BOTH_ACCEPTED'">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 상태 -> 예정 중으로 바꾸기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form>
                                                    <v-btn x-large color=""
                                                           @click="updateLessonToScheduled(lesson.id)"> 업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 취소 하기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form v-model="cancelValidation">
                                                    <v-textarea
                                                            outlined
                                                            label="비고 - 나중을 위해 꼭 기록하세요."
                                                            :rules="[rules.required]"
                                                            v-model="cancelLessonRequestBody.adminRemarks"
                                                            dense
                                                    ></v-textarea>
                                                    <v-btn x-large color=""
                                                           :disabled="!cancelValidation"
                                                           @click="updateLessonToCancelled(lesson.id, cancelLessonRequestBody)">
                                                        과외 취소하기
                                                    </v-btn>
                                                </v-form>

                                                <!--                                        <v-form>-->
                                                <!--                                            <v-btn x-large color=""-->
                                                <!--                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </v-form>-->
                                            </v-card>
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </div>
                        </v-expansion-panel>
                        <br/>
                        <br/>
                    </v-expansion-panels>
                </div>

                <div v-for="lesson in getScheduledOnly(teacher.teacher.lessons)" :key="lesson.lesson_id">
                    <v-expansion-panels class="py-1">
                        <v-expansion-panel class="panel">

                            <div v-if="lesson.status == 'SCHEDULED'">
                                <v-card-title class="scheduled" v-if="lesson.status == 'SCHEDULED'"> [과외 확정] -
                                    {{lesson.status}}
                                </v-card-title>

                                <!--                            <v-card-title class="action-required" > [카톡 등 우리가 액션 필요한 과외] - BOTH_ACCEPTED</v-card-title>-->
                                <v-expansion-panel-header>
                                    과외번호: {{lesson.id}}, 학생번호: {{lesson.sid}}, 학생 이름: {{lesson.studentName}}, 학생 이메일:
                                    {{lesson.studentEmail}}, 학생 연락처: {{lesson.studentContact}}
                                    <br/>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 정보</v-card-title>
                                                <p class="font-weight-bold text-h6"> 학생 이름: <span class="blue--text">{{lesson.studentName}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 이메일: <span class="blue--text">{{lesson.studentEmail}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 연락처: <span class="blue--text">{{lesson.studentContact}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson id: <span
                                                        class="blue--text">{{lesson.id}} </span></p>
                                                <p class="font-weight-bold text-h6"> schedule: <span class="blue--text">{{lesson.scheduledAt}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson status: <span
                                                        class="blue--text">{{lesson.status}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher matching status: <span
                                                        class="blue--text">{{lesson.teacherMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student matching status: <span
                                                        class="blue--text">{{lesson.studentMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student id: <span
                                                        class="blue--text">{{lesson.sid}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher id: <span
                                                        class="blue--text">{{lesson.tid}} </span></p>
                                                <p class="font-weight-bold text-h6"> 과외 취소 이유: <span class="blue--text">{{lesson.adminRemarks}} </span>
                                                </p>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <!--                    {{lesson}}-->
                                                <v-card-title>페이지콜 (화이트보드) 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> page call link: <br/>(http:// or
                                                    https:// prefix 필수입력 ) <span class="blue--text"> {{lesson.pageCallLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="Page Call 링크"
                                                            v-model="whiteboardRequestBody.pageCallLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateWhiteboardLink(lesson.id, whiteboardRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업일지 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> 수입일지 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.reportLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="feedbackReportRequestBody.reportLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateFeedbackReportLink(lesson.id, feedbackReportRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업영상 링크 업데이트</v-card-title>
                                                <!-- 수업영상 링크는 lesson 객체내에 안쓰는 컬럼인 schedule로 일단 사용 -->
                                                <p class="font-weight-bold text-h6"> 영상 GDrive 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.schedule}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="videoLinkRequestBody.linkToVideo"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateLinkToVideo(lesson.id, videoLinkRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>

                                        <v-col v-if="lesson.status =='BOTH_ACCEPTED'">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 상태 -> 예정 중으로 바꾸기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form>
                                                    <v-btn x-large color=""
                                                           @click="updateLessonToScheduled(lesson.id)"> 업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 취소 하기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form v-model="cancelValidation">
                                                    <v-textarea
                                                            outlined
                                                            label="비고 - 나중을 위해 꼭 기록하세요."
                                                            :rules="[rules.required]"
                                                            v-model="cancelLessonRequestBody.adminRemarks"
                                                            dense
                                                    ></v-textarea>
                                                    <v-btn x-large color=""
                                                           :disabled="!cancelValidation"
                                                           @click="updateLessonToCancelled(lesson.id, cancelLessonRequestBody)">
                                                        과외 취소하기
                                                    </v-btn>
                                                </v-form>

                                                <!--                                        <v-form>-->
                                                <!--                                            <v-btn x-large color=""-->
                                                <!--                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </v-form>-->
                                            </v-card>
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </div>

                        </v-expansion-panel>
                        <br/>
                        <br/>
                    </v-expansion-panels>
                </div>


                <div v-for="lesson in getCancelledOnly(teacher.teacher.lessons)" :key="lesson.lesson_id">
                    <v-expansion-panels class="py-1">
                        <v-expansion-panel class="panel">

                            <div v-if="lesson.status == 'CANCELLED'">
                                <v-card-title class="cancelled" v-if="lesson.status == 'CANCELLED'"> [과외 취소] -
                                    {{lesson.status}}
                                </v-card-title>
                                <v-expansion-panel-header>
                                    과외번호: {{lesson.id}}, 학생번호: {{lesson.sid}}, 학생 이름: {{lesson.studentName}}, 학생 이메일:
                                    {{lesson.studentEmail}}, 학생 연락처: {{lesson.studentContact}}
                                    <br/>

                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 정보</v-card-title>
                                                <p class="font-weight-bold text-h6"> 학생 이름: <span class="blue--text">{{lesson.studentName}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 이메일: <span class="blue--text">{{lesson.studentEmail}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> 학생 연락처: <span class="blue--text">{{lesson.studentContact}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson id: <span
                                                        class="blue--text">{{lesson.id}} </span></p>
                                                <p class="font-weight-bold text-h6"> schedule: <span class="blue--text">{{lesson.scheduledAt}} </span>
                                                </p>
                                                <p class="font-weight-bold text-h6"> lesson status: <span
                                                        class="blue--text">{{lesson.status}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher matching status: <span
                                                        class="blue--text">{{lesson.teacherMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student matching status: <span
                                                        class="blue--text">{{lesson.studentMatchingStatus}} </span></p>
                                                <p class="font-weight-bold text-h6"> student id: <span
                                                        class="blue--text">{{lesson.sid}} </span></p>
                                                <p class="font-weight-bold text-h6"> teacher id: <span
                                                        class="blue--text">{{lesson.tid}} </span></p>
                                                <p class="font-weight-bold text-h6"> 과외 취소 이유: <span class="blue--text">{{lesson.adminRemarks}} </span>
                                                </p>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <!--                    {{lesson}}-->
                                                <v-card-title>페이지콜 (화이트보드) 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> page call link: <br/>(http:// or
                                                    https:// prefix 필수입력 ) <span class="blue--text"> {{lesson.pageCallLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="Page Call 링크"
                                                            v-model="whiteboardRequestBody.pageCallLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateWhiteboardLink(lesson.id, whiteboardRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col md="4">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>수업일지 링크 업데이트</v-card-title>

                                                <p class="font-weight-bold text-h6"> 수입일지 링크: <br/>(http:// or https://
                                                    prefix 필수입력 )<span class="blue--text"> {{lesson.reportLink}} </span>
                                                </p>
                                                <v-form>
                                                    <v-text-field
                                                            outlined
                                                            label="수업 일지 링크"
                                                            v-model="feedbackReportRequestBody.reportLink"
                                                            dense
                                                    ></v-text-field>
                                                    <v-btn x-large color=""
                                                           @click="updateFeedbackReportLink(lesson.id, feedbackReportRequestBody)">
                                                        업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col v-if="lesson.status =='BOTH_ACCEPTED'">
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 상태 -> 예정 중으로 바꾸기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form>
                                                    <v-btn x-large color=""
                                                           @click="updateLessonToScheduled(lesson.id)"> 업데이트
                                                    </v-btn>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card class="pa-4" max-width="500">
                                                <v-card-title>과외 취소 하기</v-card-title>

                                                <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue--text"> {{lesson.status}} </span>
                                                </p>
                                                <v-form v-model="cancelValidation">
                                                    <v-textarea
                                                            outlined
                                                            label="비고 - 나중을 위해 꼭 기록하세요."
                                                            :rules="[rules.required]"
                                                            v-model="cancelLessonRequestBody.adminRemarks"
                                                            dense
                                                    ></v-textarea>
                                                    <v-btn x-large color=""
                                                           :disabled="!cancelValidation"
                                                           @click="updateLessonToCancelled(lesson.id, cancelLessonRequestBody)">
                                                        과외 취소하기
                                                    </v-btn>
                                                </v-form>

                                                <!--                                        <v-form>-->
                                                <!--                                            <v-btn x-large color=""-->
                                                <!--                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트-->
                                                <!--                                            </v-btn>-->
                                                <!--                                        </v-form>-->
                                            </v-card>
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </div>
                            <!--                        <v-card-title class="scheduled" v-if="lesson.status == 'SCHEDULED'"> [과외 확정] - {{lesson.status}}</v-card-title>-->
                            <!--                        <v-card-title class="cancelled" v-if="lesson.status == 'CANCELLED'"> [과외 취소] - {{lesson.status}}</v-card-title>-->
                            <!--                        <v-expansion-panel-header>-->
                            <!--                            과외번호: {{lesson.id}}, 학생번호: {{lesson.sid}}, 학생 이름: {{lesson.studentName}}, 학생 이메일:-->
                            <!--                            {{lesson.studentEmail}}, 학생 연락처: {{lesson.studentContact}}-->
                            <!--                            <br/>-->

                            <!--                        </v-expansion-panel-header>-->
                            <!--                        <v-expansion-panel-content>-->
                            <!--                            <v-row>-->
                            <!--                                <v-col md="4">-->
                            <!--                                    <v-card class="pa-4" max-width="500">-->
                            <!--                                        <v-card-title>과외 정보</v-card-title>-->
                            <!--                                        <p class="font-weight-bold text-h6"> 학생 이름: <span class="blue&#45;&#45;text">{{lesson.studentName}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> 학생 이메일: <span class="blue&#45;&#45;text">{{lesson.studentEmail}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> 학생 연락처: <span class="blue&#45;&#45;text">{{lesson.studentContact}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> lesson id: <span class="blue&#45;&#45;text">{{lesson.id}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> schedule: <span class="blue&#45;&#45;text">{{lesson.scheduledAt}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> lesson status: <span class="blue&#45;&#45;text">{{lesson.status}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> teacher matching status: <span class="blue&#45;&#45;text">{{lesson.teacherMatchingStatus}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> student matching status: <span class="blue&#45;&#45;text">{{lesson.studentMatchingStatus}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> student id: <span class="blue&#45;&#45;text">{{lesson.sid}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> teacher id: <span class="blue&#45;&#45;text">{{lesson.tid}} </span></p>-->
                            <!--                                        <p class="font-weight-bold text-h6"> 과외 취소 이유: <span class="blue&#45;&#45;text">{{lesson.adminRemarks}} </span></p>-->
                            <!--                                    </v-card>-->
                            <!--                                </v-col>-->
                            <!--                                <v-col md="4">-->
                            <!--                                    <v-card class="pa-4" max-width="500">-->
                            <!--                                        &lt;!&ndash;                    {{lesson}}&ndash;&gt;-->
                            <!--                                        <v-card-title>페이지콜 (화이트보드) 링크 업데이트</v-card-title>-->

                            <!--                                        <p class="font-weight-bold text-h6"> page call link: <br/>(http:// or https:// prefix 필수입력 ) <span class="blue&#45;&#45;text"> {{lesson.pageCallLink}} </span>-->
                            <!--                                        </p>-->
                            <!--                                        <v-form>-->
                            <!--                                            <v-text-field-->
                            <!--                                                    outlined-->
                            <!--                                                    label="Page Call 링크"-->
                            <!--                                                    v-model="whiteboardRequestBody.pageCallLink"-->
                            <!--                                                    dense-->
                            <!--                                            ></v-text-field>-->
                            <!--                                            <v-btn x-large color=""-->
                            <!--                                                   @click="updateWhiteboardLink(lesson.id, whiteboardRequestBody)"> 업데이트-->
                            <!--                                            </v-btn>-->
                            <!--                                        </v-form>-->
                            <!--                                    </v-card>-->
                            <!--                                </v-col>-->
                            <!--                                <v-col md="4">-->
                            <!--                                    <v-card class="pa-4" max-width="500">-->
                            <!--                                        <v-card-title>수업일지 링크 업데이트</v-card-title>-->

                            <!--                                        <p class="font-weight-bold text-h6"> 수입일지 링크: <br/>(http:// or https:// prefix 필수입력 )<span class="blue&#45;&#45;text"> {{lesson.reportLink}} </span>-->
                            <!--                                        </p>-->
                            <!--                                        <v-form>-->
                            <!--                                            <v-text-field-->
                            <!--                                                    outlined-->
                            <!--                                                    label="수업 일지 링크"-->
                            <!--                                                    v-model="feedbackReportRequestBody.reportLink"-->
                            <!--                                                    dense-->
                            <!--                                            ></v-text-field>-->
                            <!--                                            <v-btn x-large color=""-->
                            <!--                                                   @click="updateFeedbackReportLink(lesson.id, feedbackReportRequestBody)"> 업데이트-->
                            <!--                                            </v-btn>-->
                            <!--                                        </v-form>-->
                            <!--                                    </v-card>-->
                            <!--                                </v-col>-->
                            <!--                                <v-col v-if="lesson.status =='BOTH_ACCEPTED'">-->
                            <!--                                    <v-card class="pa-4" max-width="500">-->
                            <!--                                        <v-card-title>과외 상태 -> 예정 중으로 바꾸기 </v-card-title>-->

                            <!--                                        <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue&#45;&#45;text"> {{lesson.status}} </span>-->
                            <!--                                        </p>-->
                            <!--                                        <v-form>-->
                            <!--                                            <v-btn x-large color=""-->
                            <!--                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트-->
                            <!--                                            </v-btn>-->
                            <!--                                        </v-form>-->
                            <!--                                    </v-card>-->
                            <!--                                </v-col>-->
                            <!--                                <v-col>-->
                            <!--                                    <v-card class="pa-4" max-width="500">-->
                            <!--                                        <v-card-title>과외 취소 하기</v-card-title>-->

                            <!--                                        <p class="font-weight-bold text-h6"> 현재 과외 상태: <span class="blue&#45;&#45;text"> {{lesson.status}} </span>-->
                            <!--                                        </p>-->
                            <!--                                        <v-form v-model="cancelValidation">-->
                            <!--                                            <v-textarea-->
                            <!--                                                    outlined-->
                            <!--                                                    label="비고 - 나중을 위해 꼭 기록하세요."-->
                            <!--                                                    :rules="[rules.required]"-->
                            <!--                                                    v-model="cancelLessonRequestBody.adminRemarks"-->
                            <!--                                                    dense-->
                            <!--                                            ></v-textarea>-->
                            <!--                                            <v-btn x-large color=""-->
                            <!--                                                   :disabled="!cancelValidation"-->
                            <!--                                                   @click="updateLessonToCancelled(lesson.id, cancelLessonRequestBody)"> 과외 취소하기-->
                            <!--                                            </v-btn>-->
                            <!--                                        </v-form>-->

                            <!--                                        &lt;!&ndash;                                        <v-form>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                            <v-btn x-large color=""&ndash;&gt;-->
                            <!--&lt;!&ndash;                                                   @click="updateLessonToScheduled(lesson.id)"> 업데이트&ndash;&gt;-->
                            <!--&lt;!&ndash;                                            </v-btn>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        </v-form>&ndash;&gt;-->
                            <!--                                    </v-card>-->
                            <!--                                </v-col>-->

                            <!--                            </v-row>-->
                            <!--                        </v-expansion-panel-content>-->
                        </v-expansion-panel>
                        <br/>
                        <br/>
                    </v-expansion-panels>
                </div>

            </v-card>

        </v-container>
    </div>
</template>

<script>

    import UserService from '../../services/user.service'
    import LessonService from '../../services/lesson.service'

    export default {
        name: "ScheduledLessons",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedIndex === -1 ? 'new item' : 'edit item'
            },
            // actionRequiredLessons: function () {
            //     return this.userTeachers.filter(function (teacher) {
            //         return teacher.lessons.filter(function (lesson) {
            //             return lesson.status == 'BOTH_ACCEPTED'
            //         })
            //     })
            // },
            // scheduledLessons: function () {
            //     return this.userTeachers.teacher.teacher.lessons.filter(function (lesson) {
            //         return lesson.status == 'SCHEDULED';
            //     })
            // },
            // cancelledLessons: function () {
            //     return this.userTeachers.teacher.teacher.lessons.filter(function (lesson) {
            //         return lesson.status == 'CANCELLED';
            //     })
            // }
        },
        mounted() {

            UserService.getAllUserTeachers().then(
                (response) => {

                    this.userTeachers = response.data;
                    for (let i = 0; i < this.userTeachers.length; i++) {
                        console.log(this.userTeachers[i].teacher.lessons)
                        //     for (let j =0; j < this.userTeachers[i].teacher.lessons.length; j++) {
                        //         // console.log(this.userTeachers[i].teacher.lessons[j].status)
                        //     }
                    }


                },
                (error) => {
                    this.userTeachers =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            LessonService.getAllLessons().then(
                (response) => {
                    this.allLessons = response.data;
                    return response.data;
                },
                (error) => {
                    this.lessonResponseBody =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data: () => ({
            rules: {
                required: (value) => !!value || '필수 입력 값입니다.',
            },
            cancelValidation: false,
            userTeachers: [],
            allLessons: [],
            studentContent: [],
            studentsInLessons: [],
            test: [],
            users: [],
            allUserInfo: {},
            updateResponseBody: '',
            videoLinkRequestBody: {
                linkToVideo: '',
            },
            whiteboardRequestBody: {
                pageCallLink: "",
            },
            feedbackReportRequestBody: {
                reportLink: "",
            },

            cancelLessonRequestBody: {
                adminRemarks: "",
            }
        }),

        methods: {
            getBothAcceptedOnly(lessons) {
              let listToReturn = [];
              for (let i =0; i < lessons.length; i++) {
                  if (lessons[i].status == 'BOTH_ACCEPTED') {
                      listToReturn.push(lessons[i])
                  }
              }
              return listToReturn;
            },

            getNotStartedOnly(lessons) {
                let listToReturn = [];
                for (let i =0; i < lessons.length; i++) {
                    if (lessons[i].status == 'NOT_STARTED') {
                        listToReturn.push(lessons[i])
                    }
                }
                return listToReturn;
            },
            getTeacherAcceptedOnly(lessons) {
                let listToReturn = [];
                for (let i =0; i < lessons.length; i++) {
                    if (lessons[i].status == 'TEACHER_ACCEPTED') {
                        listToReturn.push(lessons[i])
                    }
                }
                return listToReturn;
            },

            getScheduledOnly(lessons) {
                let listToReturn = [];
                for (let i =0; i < lessons.length; i++) {
                    if (lessons[i].status == 'SCHEDULED') {
                        listToReturn.push(lessons[i])
                    }
                }
                return listToReturn;
            },

            getCancelledOnly(lessons) {
                let listToReturn = [];
                for (let i =0; i < lessons.length; i++) {
                    if (lessons[i].status == 'CANCELLED') {
                        listToReturn.push(lessons[i])
                    }
                }
                return listToReturn;
            },

            updateWhiteboardLink(lessonId, requestBody) {
                LessonService.updatePageCallLink(lessonId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        this.updateResponseBody = response.data;
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                        //     "userId": teacherId,
                        // }, teacherId);
                        // return response.body;


                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.updateResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateLinkToVideo(lessonId, requestBody) {
                LessonService.updateLinkToVideo(lessonId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        this.updateResponseBody = response.data;
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                        //     "userId": teacherId,
                        // }, teacherId);
                        // return response.body;


                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.updateResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            updateFeedbackReportLink(lessonId, requestBody) {
                LessonService.updateFeedbackReportLink(lessonId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        this.updateResponseBody = response.data;
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                        //     "userId": teacherId,
                        // }, teacherId);
                        // return response.body;


                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.updateResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateLessonToScheduled(lessonId) {
                LessonService.updateLessonToScheduled(lessonId).then(
                    (response) => {
                        console.log(response.data);
                        this.updateResponseBody = response.data;
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                        //     "userId": teacherId,
                        // }, teacherId);
                        // return response.body;
                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.updateResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateLessonToCancelled(lessonId, requestBody) {
                LessonService.updateLessonToCancelled(lessonId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        this.updateResponseBody = response.data;
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                        //     "userId": teacherId,
                        // }, teacherId);
                        // return response.body;
                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.updateResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

        }
    }
</script>

<style scoped>

    .container-background {
        background-color: #F0F0F0 !important;

    }

    .teacher-card {
        /*background-color: #ECE9E6 !important;*/

    }

    .panel {
        background-color: #fffffb !important;
        /*color: #A20916 !important;*/
        /*color: ;*/
    }

    .teacher-info {
        font-size: 20px;
        font-weight: bold;
    }

    .action-required {
        color: #2200cc;
    }

    .scheduled {
        color: green;
    }

    .cancelled {
        color: red;
    }
</style>
