<template>
    <img src="https://i.postimg.cc/9Qw1D6Nt/medium-icon.png">
</template>

<script>
    export default {
        name: "MediumIcon"
    }
</script>

<style scoped>

</style>
