<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    기타 정보
                    <v-divider class="mt-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    학생들이 처음으로 접하게될 선생님의 자기소개서를 입력해주세요.
                    <br/>
                    <br/>
                    카카오톡 ID로는 중요 알림 메세지가 전송될 예정이니 꼭 확인하고 입력 부탁드립니다.
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <!-- 대학원 재학 이상 -->
                <v-form v-model="formValid" class="">
                    <span>
                    <v-select
                            v-model="teacherContent.location"
                            :items="countryItems"
                            :rules="[v => !!v || '필수 입력']"
                            label="현재 거주 국가는 어디신가요?"
                            required
                            class="select"
                    ></v-select>
                    <v-text-field v-if="teacherContent.location == '직접입력'"
                                  v-model="currentLocation"
                                  :rules="[v => !!v || '필수 입력']"
                                  label="거주국가"
                                  required>
                    </v-text-field>
                    <v-textarea
                            class="select"
                            outlined
                            v-model="teacherContent.personalIntro"
                            flat
                            placeholder="ex) 미국 현지 동부 고등학생들 위주로 과외 경험이 있어요."
                            :rules="[rules.required, rules.maxLength]"
                            :messages="['자기 소개 (위 내용 외 추가 내용위주로만 작성 해주세요.)']"
                    ></v-textarea>
                    <v-text-field
                            class="select"
                            v-model="teacherContent.kakaoId"
                            label="카카오톡 ID"
                            prepend-icon="$vuetify.icons.kakao"
                            :rules="[rules.required]"
                            required>
                    </v-text-field>
                    </span>
                    <div class="pt-5">
                    <span class="" v-show="teacherContent.registerStatus == 'NOT_STARTED'">

                        <v-btn
                                class="mr-3"
                                :disabled="!formValid"
                                @click="updateTeacherInfo(currentUser.id, teacherContent)"
                                color="secondary"
                        >
                                &nbsp; 완료 &nbsp;
                            </v-btn>
                        </span>
                        <v-btn
                                class="mr-3"
                                v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                                :disabled="!formValid"
                                color="secondary"
                                @click="updateTeacherInfo(currentUser.id, teacherContent)">
                            수정하기
                        </v-btn>
                        <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                               :to="'/teacher/register/lecture/info'" text>
                            Back
                        </v-btn>
                        <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                               :to="'/teacher/register/teacher/summary'" text>
                            Back
                        </v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            console.log(this.teacherContent)

        },
        data() {
            return {
                countryItems: info.countryItems,
                teacherContent: "",
                formValid: false,
                undergrad: "",
                preferredCoverMaterial: info.teacherRegister.preferredCoverMaterial,
                preferredLessonMaterial: info.teacherRegister.preferredLessonMaterials,
                preferredLessonStyle: info.teacherRegister.preferredLessonStyles,


                rules: {
                    required: (value) => !!value || '필수 입력',
                    maxLength: v => (v || '' ).length <= 250 || 'Description must be 250 characters or less'

        },
            }
        },
        methods: {
            updateTeacherInfo(userId, requestBody) {

                if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                    requestBody.registerStatus = 'REGISTER_INFO_FILLED'
                }
                if (this.teacherContent.location == '직접입력') {
                    this.teacherContent.location = this.currentLocation;
                }
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/teacher/register/teacher/summary")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

    .select {
        max-width: 500px;
    }
</style>
