<template>
    <v-container>

        <!--        <h1>VERIFY</h1>-->
        <!--        {{teacherContent}}-->
        <v-card
                class="mx-auto pa-5 primary--text"
                max-width="750"
                outlined
        >
                <span
                        :class="{
            'text-h7': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                {{currentUser.firstName}} 선생님, 저희 IVY ED 에 가입 해주셔서 감사합니다!
                    <v-divider class="mt-3 mb-3"> </v-divider>
                가입정보 확인 및 서류제출만 하시면 선생님 등록이 완료됩니다!
                </span>
        </v-card>
        <v-card
                class="mx-auto pa-5 mt-3 primary--text"
                max-width="750"
                outlined
        >
            <v-card-title>
                선생님 정보 [WIP]
            </v-card-title>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td>
                            <strong>
                                <h3>
                                    성함
                                </h3>
                            </strong>
                        </td>
                        <td>
                            <h3>
                                {{currentUser.lastName}} {{currentUser.firstName}}
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                <h3>
                                    희망과목
                                </h3>
                            </strong>
                        </td>
                        <td>
                            <ul>
                                <h3>
                                    <li v-for="(item) in teacherContent.subjectsOfExpertise" :key="item.id">{{item}}
                                    </li>
                                </h3>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                <h3>
                                    학력
                                </h3>
                            </strong>
                        </td>
                        <td>
                            <ul>
                                <h3>
                                    <li>고등학교 - {{teacherContent.highSchool}}</li>
                                    <li> {{teacherContent.highestLevelOfEducation}} - {{teacherContent.undergrad}}</li>
                                </h3>
                            </ul>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <strong>
                                <h3>
                                    현재 국가
                                </h3>
                            </strong>
                        </td>
                        <td>
                            <h3>
                                {{teacherContent.location}}
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                <h3>
                                    희망과외 요일
                                </h3>
                            </strong>
                        </td>
                        <td>
                            <ul>

                                <h3>
                                    <li v-for="(item) in teacherContent.availableDays" :key="item.id">{{item}}
                                    </li>
                                </h3>

                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                <h3>
                                    희망과외 시간
                                </h3>
                            </strong>
                        </td>
                        <td>
                            <ul>

                                <h3>
                                    <li v-for="(item) in teacherContent.availableTimes" :key="item.id">{{item}}
                                    </li>
                                </h3>

                            </ul>
                        </td>
                    </tr>


                    </tbody>

                </template>

            </v-simple-table>
            <!--            <v-btn @click="updateTeacherInfo(currentUser.id, teacherContent)"> GO !</v-btn>-->
            <!--            {{pageDone}}-->
        </v-card>
        <v-card
                class="mx-auto pa-5 mt-3 primary--text"
                max-width="750"
                outlined
        >
            <v-card-title>
                서류 제출
            </v-card-title>
            <v-divider></v-divider>
            <v-container>
                <v-row class="">
                    <v-col cols="12">
                        선생님 등록 모든 과정이 거의 끝났습니다.
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        선생님의 이메일로 위 정보 확인에 필요한 서류를 요청 드리겠습니다.
                    </v-col>
                </v-row>
                <v-row class="mb-5">
                    <v-col>
                        아래 이메일 받기 버튼을 눌러 주시면 가입정보 섹션이 모두 완료 됩니다. :)
                    </v-col>
                </v-row>
                <!--                <h1>{{teacherContent}}</h1>-->
                <v-divider></v-divider>
                <!--                <v-checkbox-->
                <!--                        v-model="agree"-->
                <!--                        :rules="[v => !!v || 'You must agree to continue!']"-->
                <!--                        label="개인정보 수집 동의"-->
                <!--                        required-->
                <!--                ></v-checkbox>-->
                <!--                <h1>{{agree}}</h1>-->
                <v-row>
                    <!--                    <h1>-->
                    <!--                        프로필 페이지로 돌아가지지 않음.-->
                    <!--                    </h1>-->
                    <v-col>
                        <v-btn color="primary"
                               @click="updateTeacherInfo(currentUser.id, teacherContent)"
                               class="mt-4 mr-4"
                               large>이메일 받기
                        </v-btn>
                        <v-btn color="primary"
                               :to="'/teacher/register'"
                               class="mt-4 mr-4"
                               large>정보 수정하기
                        </v-btn>
                    </v-col>

                </v-row>

            </v-container>
            <v-simple-table>
                <template v-slot:default>

                </template>

            </v-simple-table>
            <!--            <v-btn @click="updateTeacherInfo(currentUser.id, teacherContent)"> GO !</v-btn>-->
            <!--            {{pageDone}}-->
        </v-card>

    </v-container>
</template>

<script>
    import TeacherService from '../../services/teacher.service'
    import info from "../../../info";

    export default {
        name: "TeacherRegister",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                agree: false,
                e6: 1,
                pageDone: false,
                teacherRegisterData: {
                    subjects: [],
                    selectedSchools: [],
                    highSchool: [],
                    currentRegion: [],
                },
                select: [],
                highestLevelOfEducation: info.teacherRegister.highestLevelOfEducation,
                items: info.teacherRegister.subjectsOfExpertise,
                teacherContent: "",
                schools: info.americanUniversities,
                timeSlots: info.timeSlots
            }
        },
        methods: {
            remove(item) {
                this.chips.splice(this.chips.indexOf(item), 1)
                this.chips = [...this.chips]
            },

            updateTeacherInfo(userId, requestBody) {
                this.teacherContent.registerStatus = 'PENDING';
                TeacherService.updateTeacherInfo(userId, requestBody).then(
                    (response) => {
                        console.log(response.data)
                        this.$router.push("/profile")
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }

        }
    }
</script>

<style scoped>

</style>
