<template>
    <div>
        <v-card
                class="mx-auto primary--text pa-2 ma-2 hidden-sm-and-down"
                max-width="750"
                elevation="0"
                color=""
                height="1000"
        >
            <v-card-title class="justify-center mt-15"
            >
                <!--                    <h1>상품 정보</h1>-->
                <span
                        :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                >

            </span>
            </v-card-title>
            <v-card outlined class="rounded-lg ma-5 pa-5 hidden-sm-and-down" >
                <v-card-title class="text-h5 pa-3 black--text justify-center font-weight-bold primary--text">
                    회원 가입이 완료 되었습니다!

                </v-card-title>
                <div class="text-center text-h6 mt-8">
                <strong class="text-decoration-underline">이메일 인증 후</strong> 로그인 하실 수 있습니다.
                <p class=" text-h8">
                    이메일을 받지 못하셨다면 <br/>
                    스팸함을 먼저 확인 해주세요.
                </p>
                </div>
                    <div class="text-center mt-15">
                    <v-btn to="/login"
                           x-large
                           width="30%"
                           color="secondary"
                           class="font-weight-bold"
                    >
                        로그인
                    </v-btn>
                    </div>
                    <!--                <v-btn-->
                    <!--                        outlined-->
                    <!--                        rounded-->
                    <!--                        text-->
                    <!--                >-->
                    <!--                </v-btn>-->

            </v-card>

        </v-card>

        <v-card
                class="mx-auto ma-10 pa-5 py-15 primary--text hidden-md-and-up"
                max-width="700"
                flat
        >
            <v-card-title class="mb-1 text-h5 justify-center">

                <span class="text-center font-weight-bold"> 회원 가입이 완료 되었습니다! </span>
            </v-card-title>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title class="text-h6 mb-1">
<!--                        아이비에드에 가입하신걸 환영합니다.-->
                    </v-list-item-title>
<!--                    <v-list-item-title class="text-h6 mb-1">-->
<!--                        입력하신 이메일로 인증 후 로그인 하실 수 있습니다.-->
<!--                    </v-list-item-title>-->
<!--                    <v-list-item-title class="text-h7 mb-1">-->
<!--                        로그인 후에 아이비에드를 이용해주세요.-->
<!--                    </v-list-item-title>-->
                    <v-list-item-title class="text-h7 ma-2 text-center">
                        <strong class="text-decoration-underline">이메일 인증 후</strong> 로그인 하실 수 있습니다.
                        <p class=" text-h8">
                            이메일을 받지 못하셨다면 <br/>
                            스팸함을 먼저 확인 해주세요.
                        </p>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
<div class="text-center">
                <v-btn to="/login"
                       x-large
                       color="secondary"
                       class="font-weight-bold"
                       width="40%"
                >
                    로그인
                </v-btn>
</div>
        </v-card>

    </div>
</template>
<script>
    export default {
        name: 'RegisterSuccess',
    };
</script>
