<template>
    <div>
        <v-container v-if="getDefaultLanguage.includes('ko')" fluid class="pt-10 " v-show="!loading"
                     :class="{
                 }"

        >
            <!-- Main Form -->
            <v-row justify="center"
                   class="mt-1"
                   :class="{
               'mt-n10': $vuetify.breakpoint.mdAndDown,
               }"
            >
                <v-col
                        cols="auto" class="ma-0 pa-0">
                    <v-card color="" elevation="2" class="pa-5 pt-0 primary--text" max-width="600px"
                            :class="{

                }"
                    >
                        <v-form ref="registerform" v-model="isFormValid">
                            <v-row
                                    no-gutters
                                    justify="center"
                                    align="center"
                                    class="text-h4 font-weight-medium ma-5"
                                    :class="{
                            'pt-13': $vuetify.breakpoint.xs,
                            'pt-13': $vuetify.breakpoint.sm,
                            'pt-13': $vuetify.breakpoint.mdAndUp
                            }"
                            >
                                <!--                                <v-col cols="12" class="text-center hidden-sm-and-down"-->
                                <!--                                >-->
                                <!--&lt;!&ndash;                                    <IvyedLogo ></IvyedLogo>&ndash;&gt;-->
                                <!--                                </v-col>-->
                                <v-col cols="12" class="text-center font-weight-bold py-10"
                                       :class="{
                            'pt-10': $vuetify.breakpoint.smAndDown,
                            'text-h5': $vuetify.breakpoint.smAndDown
                            }">
                                    <!--                                <span :style="{color: '#b71c1c'}">-->
                                    회원가입
                                    <!--                            </span>-->
                                </v-col>

                            </v-row>
                            <v-row no-gutters justify="center" align="center" class="px-5 text-h4">
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="이름 / First Name"
                                            v-model="mainData.firstName"
                                            required
                                            :rules="[rules.required]"
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="성 / Last Name"
                                            v-model="mainData.lastName"
                                            required
                                            :rules="[rules.required]"
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <!--                                    <v-text-field-->
                                    <!--                                            outlined-->
                                    <!--                                            label="ُUsername"-->
                                    <!--                                            v-model="mainData.username"-->
                                    <!--                                            required-->
                                    <!--                                            :rules="[rules.required]"-->
                                    <!--                                            dense-->
                                    <!--                                    ></v-text-field>-->
                                    <v-text-field
                                            outlined
                                            label="이메일 (아이디)"
                                            v-model="mainData.username"
                                            required
                                            :rules="[rules.required, rules.email]"
                                            dense
                                            placeholder="abc@gmail.com"
                                    ></v-text-field>

                                </v-col>
                                <v-col md="10" cols="10" v-if="mainData.countryCode == '직접 입력'">
                                    <span
                                            :class="{
                                            'text-subtitle-2': $vuetify.breakpoint.mdAndUp,
                                            'small-text font-weight-bold': $vuetify.breakpoint.smAndDown,
                                            }"
                                            class=" secondary--text"> 국가코드와 전화번호를 직접 입력하여 주세요.</span>
                                </v-col>

                                <v-row no-gutters justify="center" align="center" class=" text-h4">
                                    <v-col
                                            cols="10"
                                            md="3"
                                    >
                                        <v-autocomplete
                                                outlined
                                                label="국가코드"
                                                required
                                                :items="countryCodes"
                                                v-model="mainData.countryCode"
                                                :rules="[rules.required]"
                                                class="font-weight-thin"
                                                dense
                                                :messages="['국가 코드']"
                                                auto-select-first
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                            cols="10"
                                            md="7"
                                    >
                                        <v-text-field
                                                outlined
                                                label="전화번호 / Phone Number"
                                                required
                                                v-model="mainData.phoneNumber"
                                                :rules="[rules.required, rules.isValidPhoneNumber]"
                                                dense
                                                :messages="['(\'-\' 없이 번호만 입력)']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="생년월일 ('-' 없이 숫자만 입력)"
                                            placeholder="20061225"
                                            v-model="mainData.birthDate"
                                            maxlength="8"
                                            required
                                            :rules="[rules.isAYear, rules.required, rules.isValidBirthdate]"
                                            dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="비밀번호"
                                            required
                                            v-model="mainData.password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.MinPassLen]"
                                            :type="showPassword ? 'text' : 'password'"
                                            @click:append="showPassword = !showPassword"
                                            dense
                                    ></v-text-field
                                    >
                                </v-col>
<!--                                <v-col cols="10">-->
<!--                                    &lt;!&ndash;                                <h1> FUKC </h1>&ndash;&gt;-->
<!--                                    <vue-tel-input-vuetify v-model="mainData.phoneNumber"></vue-tel-input-vuetify>-->
<!--                                    &lt;!&ndash;                                <h1> FUKC </h1>&ndash;&gt;-->
<!--                                </v-col>-->
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="비밀번호 확인"
                                            required
                                            v-model="mainData.passwordConfirm"
                                            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[
                    rules.required,
                    rules.passwordMatch(mainData.passwordConfirm, mainData.password),
                  ]"
                                            :type="showPasswordConfirm ? 'text' : 'password'"
                                            @click:append="showPasswordConfirm = !showPasswordConfirm"
                                            dense
                                    ></v-text-field
                                    >
                                </v-col>
                                <!-- role selection-->
                                <v-col cols="10" class="text-center">
                                    <!--                                <v-select-->
                                    <!--                                        :items="roleItems"-->
                                    <!--                                        label="학생 / 선생님"-->
                                    <!--                                        v-model="mainData.role"-->
                                    <!--                                ></v-select>-->
                                    <v-radio-group
                                            v-model="mainData.role"
                                            row
                                            mandatory
                                            :rules="[rules.required]"
                                    >
                                        <v-radio
                                                label="학생 / 학부모"
                                                value="student"

                                        ></v-radio>
                                        <v-radio
                                                label="선생님"
                                                value="teacher"

                                        ></v-radio>
                                    </v-radio-group>
                                    <template v-slot:append-item>

                                    </template>
                                </v-col>

                                <v-col cols="10" class="agreements">
<!--                                    <v-container fluid>-->
                                        <v-switch
                                                hide-details
                                                @change="turnAgreeToAll()"
                                                :label="`전체동의`"
                                        ></v-switch>
<!--                                    <input type="checkbox" id="scales" name="scales"-->
<!--                                           checked>-->
<!--&lt;!&ndash;                                    <label for="scales">Scales</label>&ndash;&gt;-->
                                        <v-checkbox :rules="termsOfServiceRequired" hide-details
                                                    class="my-checkbox"
                                                    v-model="termsOfService">
                                            <template v-slot:label>
                                                <div class="agreements">
                                                    <a
                                                            target="_blank"
                                                            href="https://ivyed.notion.site/b186e716ffd443acbccc5ce45717e762"
                                                            @click.stop
                                                    >
                                                        IVYED 이용약관</a>

                                                    동의 (필수)
                                                </div>
                                            </template>
                                        </v-checkbox>
                                        <v-checkbox :rules="personalInfoRequired" hide-details
                                                    class="my-checkbox"
                                                    v-model="personalInfoCollect">
                                            <template v-slot:label>
                                                <div class="agreements">

                                                    <a
                                                            target="_blank"
                                                            href="https://ivyed.notion.site/6d4248d37ff64ac3bd7f5c553763f5c9"
                                                            @click.stop
                                                    >개인정보 수집 및 이용</a>
                                                    동의 (필수)
                                                </div>
                                            </template>
                                        </v-checkbox>
                                        <v-checkbox hide-details v-model="mainData.marketingAgree" class="my-checkbox">
                                            <template v-slot:label>
                                                <div class="agreements">

                                                    <a
                                                            target="_blank"
                                                            href="https://ivyed.notion.site/f25259f37bfd426690222952f5dd5110"
                                                            @click.stop
                                                    >마케팅을 위한 개인정보 수집 / 이용</a>

                                                    동의 (선택)
                                                </div>
                                            </template>
                                        </v-checkbox>
                                        <v-checkbox :rules="thirdPartyRequired" hide-details v-model="thirdPartyAgree" class="my-checkbox">
                                            <template v-slot:label>
                                                <div class="agreements">
                                                    <a
                                                            target="_blank"
                                                            href="https://ivyed.notion.site/3-31aea9057e5f479b91681e5c17b29cfe"
                                                            @click.stop
                                                    >개인정보 제3자 제공</a>
                                                    동의 (필수)
                                                </div>
                                            </template>
                                        </v-checkbox>
<!--                                    </v-container>-->
                                </v-col>

                            </v-row>

                            <!-- 수강 신청 정보가 없는 경우-->
                            <div v-if="!enrollmentData">
                                <v-row justify="center" align="center" class="px-5 mt-0 mb-10">
                                    <v-col cols="10" class="text-center">
                                        <v-btn
                                                @click.prevent="handleRegister(mainData)"
                                                width="60%"
                                                rounded
                                                outlined
                                                color="primary"
                                                type="submit"
                                                class="">

                                            가입 완료

                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <!--                        <h3>{{mainData}}</h3>-->
                            <!-- 수강 신청 정보가 있는 경우-->
                            <div v-if="enrollmentData">
                                <v-row justify="center" align="center" class="px-5 mt-0 mb-10">
                                    <v-col cols="10" class="text-center">
                                        <v-btn
                                                :disabled="!isFormValid"
                                                @click.prevent="handleRegister(mainData)"
                                                width="60%"
                                                color="secondary"
                                                class="">

                                            가입 완료

                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- 가입완료 메시지 -->


                            <!-- 가입시 에러 메시지 -->
                            <!--                            {{this.$store.state.enrollmentData}}-->
                            <!--                            {{!enrollmentData}}-->
                            <div v-if="message">
                                <v-alert type="error">
                                    {{ message }}
                                </v-alert>
                            </div>
                        </v-form>

                    </v-card>

                </v-col>
            </v-row>
            <!--        <v-btn @click="loading=true">-->
            <!--            TEST-->
            <!--        </v-btn>-->

        </v-container>
        <v-container v-if="getDefaultLanguage.includes('en')" fluid class="pt-10 " v-show="!loading"
                     :class="{
                 }"

        >
            <!-- Main Form -->
            <v-row justify="center"
                   class="mt-1"
                   :class="{
               'mt-n10': $vuetify.breakpoint.mdAndDown,
               }"
            >
                <v-col
                        cols="auto" class="ma-0 pa-0">
                    <v-card color="" elevation="2" class="pa-5 pt-0 primary--text" max-width="600px"
                            :class="{

                }"
                    >
                        <v-form ref="registerform" v-model="isFormValid">
                            <v-row
                                    no-gutters
                                    justify="center"
                                    align="center"
                                    class="text-h4 font-weight-medium ma-5"
                                    :class="{
                            'pt-13': $vuetify.breakpoint.xs,
                            'pt-13': $vuetify.breakpoint.sm,
                            'pt-13': $vuetify.breakpoint.mdAndUp
                            }"
                            >
                                <!--                                <v-col cols="12" class="text-center hidden-sm-and-down"-->
                                <!--                                >-->
                                <!--&lt;!&ndash;                                    <IvyedLogo ></IvyedLogo>&ndash;&gt;-->
                                <!--                                </v-col>-->
                                <v-col cols="12" class="text-center font-weight-bold py-10"
                                       :class="{
                            'pt-10': $vuetify.breakpoint.smAndDown,
                            'text-h5': $vuetify.breakpoint.smAndDown
                            }">
                                    <!--                                <span :style="{color: '#b71c1c'}">-->
                                    Sign Up
                                    <!--                            </span>-->
                                </v-col>

                            </v-row>
                            <v-row no-gutters justify="center" align="center" class="px-5 text-h4">
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="First Name"
                                            v-model="mainData.firstName"
                                            required
                                            :rules="[rules.requiredEnglish]"
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Last Name"
                                            v-model="mainData.lastName"
                                            required
                                            :rules="[rules.requiredEnglish]"
                                            dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <!--                                    <v-text-field-->
                                    <!--                                            outlined-->
                                    <!--                                            label="ُUsername"-->
                                    <!--                                            v-model="mainData.username"-->
                                    <!--                                            required-->
                                    <!--                                            :rules="[rules.required]"-->
                                    <!--                                            dense-->
                                    <!--                                    ></v-text-field>-->
                                    <v-text-field
                                            outlined
                                            label="Email"
                                            v-model="mainData.username"
                                            required
                                            :rules="[rules.requiredEnglish, rules.emailEnglish]"
                                            dense
                                            placeholder="abc@gmail.com"
                                    ></v-text-field>

                                </v-col>
                                <v-col md="10" cols="10" v-if="mainData.countryCode == '직접 입력'">
                                    <span
                                            :class="{
                                            'text-subtitle-2': $vuetify.breakpoint.mdAndUp,
                                            'small-text font-weight-bold': $vuetify.breakpoint.smAndDown,
                                            }"
                                            class=" secondary--text"> 국가코드와 전화번호를 직접 입력하여 주세요.</span>
                                </v-col>

                                <v-row no-gutters justify="center" align="center" class=" text-h4">
                                    <v-col
                                            cols="10"
                                            md="3"
                                    >
                                        <v-autocomplete
                                                outlined
                                                label="Country Code"
                                                required
                                                :items="countryCodes"
                                                v-model="mainData.countryCode"
                                                :rules="[rules.requiredEnglish]"
                                                class="font-weight-thin"
                                                dense
                                                auto-select-first
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                            cols="10"
                                            md="7"
                                    >
                                        <v-text-field
                                                outlined
                                                label="Phone Number"
                                                required
                                                v-model="mainData.phoneNumber"
                                                :rules="[rules.requiredEnglish, rules.isValidPhoneNumberEnglish]"
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Birthday (YYYYMMDD)"
                                            placeholder="20061225"
                                            v-model="mainData.birthDate"
                                            maxlength="8"
                                            required
                                            messages=""
                                            :rules="[rules.isAYearEnglish, rules.requiredEnglish, rules.isValidBirthdate]"
                                            dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Password"
                                            required
                                            v-model="mainData.password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.requiredEnglish, rules.MinPassLenEnglish]"
                                            :type="showPassword ? 'text' : 'password'"
                                            @click:append="showPassword = !showPassword"
                                            dense
                                    ></v-text-field
                                    >
                                </v-col>
                                <!--                                <v-col cols="10">-->
                                <!--                                    &lt;!&ndash;                                <h1> FUKC </h1>&ndash;&gt;-->
                                <!--                                    <vue-tel-input-vuetify v-model="mainData.phoneNumber"></vue-tel-input-vuetify>-->
                                <!--                                    &lt;!&ndash;                                <h1> FUKC </h1>&ndash;&gt;-->
                                <!--                                </v-col>-->
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Confirm Password"
                                            required
                                            v-model="mainData.passwordConfirm"
                                            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[
                    rules.requiredEnglish,
                    rules.passwordMatchEnglish(mainData.passwordConfirm, mainData.password),
                  ]"
                                            :type="showPasswordConfirm ? 'text' : 'password'"
                                            @click:append="showPasswordConfirm = !showPasswordConfirm"
                                            dense
                                    ></v-text-field
                                    >
                                </v-col>
                                <!-- role selection-->
                                <v-col cols="10" class="text-center">
                                    <!--                                <v-select-->
                                    <!--                                        :items="roleItems"-->
                                    <!--                                        label="학생 / 선생님"-->
                                    <!--                                        v-model="mainData.role"-->
                                    <!--                                ></v-select>-->
                                    <v-radio-group
                                            v-model="mainData.role"
                                            mandatory
                                            :rules="[rules.requiredEnglish]"
                                    >
                                        <v-radio
                                                label="Student/Parent"
                                                value="student"

                                        ></v-radio>
                                        <v-radio
                                                label="Teacher"
                                                value="teacher"

                                        ></v-radio>
                                    </v-radio-group>
                                    <template v-slot:append-item>

                                    </template>
                                </v-col>

                                <v-col cols="10" class="agreements">
                                    <!--                                    <v-container fluid>-->
                                    <v-switch
                                            hide-details
                                            @change="turnAgreeToAll()"
                                            :label="`Accept all`"
                                    ></v-switch>
                                    <!--                                    <input type="checkbox" id="scales" name="scales"-->
                                    <!--                                           checked>-->
                                    <!--&lt;!&ndash;                                    <label for="scales">Scales</label>&ndash;&gt;-->
                                    <v-checkbox :rules="termsOfServiceRequired" hide-details
                                                class="my-checkbox"
                                                v-model="termsOfService">
                                        <template v-slot:label>
                                            <div class="agreements">
                                                <a
                                                        target="_blank"
                                                        href="https://ivyed.notion.site/b186e716ffd443acbccc5ce45717e762"
                                                        @click.stop
                                                >
                                                    Terms of Service</a>

                                                (required)
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox :rules="personalInfoRequired" hide-details
                                                class="my-checkbox"
                                                v-model="personalInfoCollect">
                                        <template v-slot:label>
                                            <div class="agreements">

                                                <a
                                                        target="_blank"
                                                        href="https://ivyed.notion.site/6d4248d37ff64ac3bd7f5c553763f5c9"
                                                        @click.stop
                                                >Personal Information Agreement</a>
                                                (required)
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox hide-details v-model="mainData.marketingAgree" class="my-checkbox">
                                        <template v-slot:label>
                                            <div class="agreements">

                                                <a
                                                        target="_blank"
                                                        href="https://ivyed.notion.site/f25259f37bfd426690222952f5dd5110"
                                                        @click.stop
                                                >Marketing Agreement</a>

                                                (optional)
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox :rules="thirdPartyRequired" hide-details v-model="thirdPartyAgree" class="my-checkbox">
                                        <template v-slot:label>
                                            <div class="agreements">
                                                <a
                                                        target="_blank"
                                                        href="https://ivyed.notion.site/3-31aea9057e5f479b91681e5c17b29cfe"
                                                        @click.stop
                                                >Disclosure of Information to Third Parties</a>
                                                (required)
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <!--                                    </v-container>-->
                                </v-col>

                            </v-row>

                            <!-- 수강 신청 정보가 없는 경우-->
                            <div v-if="!enrollmentData">
                                <v-row justify="center" align="center" class="px-5 mt-0 mb-10">
                                    <v-col cols="10" class="text-center">
                                        <v-btn
                                                @click.prevent="handleRegister(mainData)"
                                                width="60%"
                                                rounded
                                                outlined
                                                color="primary"
                                                type="submit"
                                                class="">

                                            Sign up

                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <!--                        <h3>{{mainData}}</h3>-->
                            <!-- 수강 신청 정보가 있는 경우-->
                            <div v-if="enrollmentData">
                                <v-row justify="center" align="center" class="px-5 mt-0 mb-10">
                                    <v-col cols="10" class="text-center">
                                        <v-btn
                                                :disabled="!isFormValid"
                                                @click.prevent="handleRegister(mainData)"
                                                width="60%"
                                                color="secondary"
                                                class="">

                                            Sign up

                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- 가입완료 메시지 -->


                            <!-- 가입시 에러 메시지 -->
                            <!--                            {{this.$store.state.enrollmentData}}-->
                            <!--                            {{!enrollmentData}}-->
                            <div v-if="message">
                                <v-alert type="error">
                                    {{ message }}
                                </v-alert>
                            </div>
                        </v-form>

                    </v-card>

                </v-col>
            </v-row>
            <!--        <v-btn @click="loading=true">-->
            <!--            TEST-->
            <!--        </v-btn>-->

        </v-container>

        <div v-show="loading" class="center">
            <v-progress-circular
                    :size="60"
                    :width="7"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>
    </div>

</template>

<script>
    // import VeeValidate from 'vee-validate'
    // import  from "vee-validate";
    import * as yup from "yup";
    import info from "../../info";
    // import RegisterSuccessDialog from './RegisterSuccessDialog'
    import StudentService from "../services/student.service"
    // import IvyedLogo from "./helpers/IvyedLogo";

    export default {
        name: "Register",
        components: {
            // IvyedLogo
            // VeeValidate
            // Field,
            // ErrorMessage,
            // RegisterSuccessDialog,
        },
        watch: {
            '$data': {
                handler: function (newValue) {
                    console.log('Current vaules:' + newValue.agreeToAll);

                    if (newValue.agreeToAll) {
                        console.log('Current vaules:' + newValue.agreeToAll);

                    }
                },
            }
        },
        data() {
            const schema = yup.object().shape({
                username: yup
                    .string()
                    .required("Username is required!")
                    .min(3, "Must be at least 3 characters!")
                    .max(20, "Must be maximum 20 characters!"),
                email: yup
                    .string()
                    .required("Email is required!")
                    .email("Email is invalid!")
                    .max(50, "Must be maximum 50 characters!"),
                password: yup
                    .string()
                    .required("Password is required!")
                    .min(6, "Must be at least 6 characters!")
                    .max(40, "Must be maximum 40 characters!"),
            });

            return {
                countryCodes: info.countryCodes,
                isFormValid: false,
                termsOfService: false,
                personalInfoCollect: false,

                thirdPartyAgree: false,
                agreeToAll: false,
                row: null,
                showRegisterSuccessDialog: false,
                successful: false,
                loading: false,
                message: "",
                schema,
                menu: false,
                tempCategory: '',
                roleItems: ['teacher', 'student'],
                roleTeacher: "",
                roleStudent: "",
                countryCodeSelf: false,
                countryCode: "",
                mainData: {
                    firstName: '',
                    lastName: '',
                    username: '',
                    birthDate: '',
                    email: '',
                    roleType: '',
                    password: '',
                    phoneNumber: '',
                    countryCode: '',
                    role: [],
                    marketingAgree: '',
                    // passwordConfirm: '',
                },
                valid: true,
                showPassword: false,
                showPasswordConfirm: false,
                rules: {
                    required: (value) => !!value || '필수 입력',
                    requiredEnglish: (value) => !!value || 'Required',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || '이메일을 올바르게 입력해주세요.';
                    },
                    emailEnglish: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Enter a valid email.';
                    },
                    isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    isValidBirthdateEnglish: (value) => (value.length == 8) || 'Please enter 8 digits in YYYYMMDD.',
                    // isValidPhoneNumber: (value) => (value.length == 11) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    isValidPhoneNumber: (value) => (value.match(/^[0-9]+$/)) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    isValidPhoneNumberEnglish: (value) => (value.match(/^[0-9]+$/)) || 'Please enter digits only.',
                    // isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => isNaN(value) || '핸드폰 번호는 '-' 없이 11자리 숫자로 입력 해주세요',
                    isAYear: (value) => (value >= 19400101 && value <= 20251231 && value.length == 8) || '생일은 8자리 숫자 및 1940 - 2022년만 유효합니다. ',
                    isAYearEnglish: (value) => (value >= 19400101 && value <= 20251231 && value.length == 8) || 'Invalid Birthday. Only years between 1940 and 2022 are valid.',
                    hourlyRate: (value) => (value >= 10 && value <= 2000) || 'Must Be between 10 and 2000',
                    biggerthanzero: (value) => value > 0 || 'Can not work less than 1 month',
                    overviewLength: (v) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        (v.length >= 50 && v.length <= 5000) || 'Min 50 characters, Max 5000 characters',
                    emptyArray: (v) => v.length > 0 || 'Min One Skill is needed',
                    passwordMatch: (v1, v2) => v1 === v2 || '비밀번호가 일치하지 않습니다.',
                    passwordMatchEnglish: (v1, v2) => v1 === v2 || 'Passwords do not match!',
                    MinPassLen: (v) => v.length >= 8 || '8자 이상의 비밀번호만 허용 됩니다.',
                    MinPassLenEnglish: (v) => v.length >= 8 || 'At least 8 characters are required.',
                },
                sendRequest: false,
            };
        },
        computed: {
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            },
            enrollmentData() {
                return this.$store.state.enrollmentData;
            },
            personalInfoRequired() {
                return [
                    this.personalInfoCollect == true || ""
                ];
            },
            termsOfServiceRequired() {
                return [
                    this.termsOfService == true || ""
                ];
            },
            thirdPartyRequired() {
                return [
                    this.thirdPartyAgree == true || ""
                ];
            }
        },
        mounted() {
            // console.log(this.$route)
            if (this.loggedIn) {
                this.$router.push("/profile");
            }
            if (this.termsOfService && this.personalInfoCollect && this.marketingAgree && this.thirdPartyAgree) {
                this.agreeToAll = true;
            }
        },
        methods: {

            sayHello() {
                console.log("hello")
            },

            checkAllAgreeStatus() {
                this.agreeToAll = !this.agreeToAll
                if (this.agreeToAll) {
                    this.termsOfService = true;
                    this.personalInfoCollect = true;
                    this.marketingAgree = true;
                    this.thirdPartyAgree = true;
                } else {
                    this.termsOfService = false;
                    this.personalInfoCollect = false;
                    this.marketingAgree = false;
                    this.thirdPartyAgree = false;
                }
            },

            checkAgreeStatuses() {
                if (this.termsOfService && this.personalInfoCollect && this.marketingAgree && this.thirdPartyAgree) {
                    this.agreeToAll = true;
                }
                if (this.termsOfService == false ||
                    this.personalInfoCollect == false ||
                    this.marketingAgree == false ||
                    this.thirdPartyAgree == false) {
                    this.agreeToAll = false;
                }
            },
            turnAgreeToAll() {
                this.agreeToAll = !this.agreeToAll
                if (!this.agreeToAll) {
                    this.termsOfService = false;
                    this.personalInfoCollect = false;
                    this.mainData.marketingAgree = false;
                    this.thirdPartyAgree = false;
                } else {
                    this.termsOfService = true;
                    this.personalInfoCollect = true;
                    this.mainData.marketingAgree = true;
                    this.thirdPartyAgree = true;
                }

            },

            handleErrorMessages(message) {
                console.log(message)
                if (message.includes("Username")) {
                    this.message = "이미 사용중인 Username 입니다."
                } else if (message.includes("Email")) {
                    this.message = "이미 사용중인 이메일 주소입니다."
                } else if (message.includes("parse")) {
                    this.message = "위 필드를 모두 입력하셔야 가입이 가능합니다."
                } else if (message.includes("Bad")) {
                    this.message = "아이디 혹은 비밀번호가 일치하지 않습니다."
                }
            },

            updateStudentEnrollmentData(userId) {

                let requestBody = {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedLessonDuration: "",
                    tablet: "",
                };
                requestBody.subjectsOfInterest.push(this.enrollmentData.lessonCategory);
                requestBody.subSubjects.push(this.enrollmentData.lessonSubCateogry);
                requestBody.expectedNumberOfLessonsPerWeek = this.enrollmentData.expectedNumberOfLessonsPerWeek;
                requestBody.expectedLessonDuration = this.enrollmentData.expectedLessonDuration;
                requestBody.tablet = this.enrollmentData.tablet;
                console.log(requestBody);
                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(this.studentContent)
                    }
                )
                this.$router.push("/enroll");
                return userId;
            },


            moveToMain() {
                this.$router.push("/");
            },
            handleRegister(user) {

                this.loading = true;
                this.message = "";
                this.successful = false;
                user.username = user.username.toLowerCase();
                user.email = user.username;

                this.$store.dispatch("auth/register", user).then(
                    (data) => {
                        this.message = data.message;
                        this.successful = true;
                        this.loading = false;
                        var response = data.data;

                        //add enrollment data to STUDENT if
                        // 1. has enrollment data
                        // 2. is a student
                        if (this.enrollmentData && this.mainData.role[0] == 'ROLE_STUDENT') {
                            this.updateStudentEnrollmentData(response.id)
                        }

                        // either make the user login or make the user login automatically
                        this.$router.push("/registerSuccess");
                    },
                    (error) => {
                        this.tempMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.handleErrorMessages(this.tempMessage);

                        this.successful = false;
                        this.loading = false;
                    }
                );
            },
        },
    };
</script>

<style scoped>
    body {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }

    label {
        display: block;
        margin-top: 10px;
    }

    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }

    .register-style {
        /*background: url(../assets/background_option_001_blurred.png) center center/cover no-repeat;*/
    }
    .small-text {
        font-size: 12px;
    }
    .agreements {
        font-size: 15px !important;
        padding: 0 !important;
        margin-top: 0 !important;
        /*!important;*/
    }
    /*input[type=checkbox] {*/
    /*    transform: scale(0.5);*/
    /*}*/
    .my-checkbox .v-label {
        font-size: 8px;
        padding: 0 !important;
        margin: 0 !important;
    }
</style>
