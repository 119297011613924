<template>
<img src="../../assets/kakao-ch-final.png">
</template>

<script>
    export default {
        name: "KakaoChannelIcon"
    }
</script>

<style scoped>
</style>
