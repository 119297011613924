<template>
    <div>
        <v-container>
            <!--        <h1>{{currentUser}}</h1>-->
            <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


                <v-card
                        class="mx-auto pa-5 primary--text"
                        max-width="800"
                        elevation="0"
                >
                <span
                        class="font-weight-bold"
                        :class="{
                        'text-h6': $vuetify.breakpoint.mdAndDown,
                        'text-h5': $vuetify.breakpoint.lgAndUp
                        }">
                    계좌 정보 <v-icon color="primary"> mdi-bank</v-icon>
                    <v-divider class="mt-3"></v-divider>
                </span>
                    <p class="black--text text-subtitle-2">
                        <!--                    수업이 가능한 총 기간과 주별 강의 시간을 기입해주세요!-->
                        <!--                    <br/>-->
                    </p>
                </v-card>
                <!--        <h1>{{teacherContent}}</h1>-->
                <!--            {{items}}-->
                <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
                <v-card
                        class="mx-auto pa-5 mt-2 black--text justify-center"
                        max-width="800"
                        elevation="0"
                >

                    <v-form v-model="formValid">
                        <!--                    <v-card-title-->
                        <!--                            class="font-weight-bold"-->
                        <!--                            :class="{}">-->
                        <!--                        희망 과외 기간-->
                        <!--                    </v-card-title>-->
                        <div v-if="!bankAccountInfoAvailable">
                        <v-row class="mb-8">
                            <v-col
                                    class=""
                                    cols="12"
                                    sm="8">
                                <v-select
                                        v-model="teacherContent.bankAccountType"
                                        :items="accountType"
                                        label="은행 / 증권"
                                        chips
                                        :rules="[rules.required]"
                                ></v-select>
                                <v-autocomplete v-if="teacherContent.bankAccountType && teacherContent.bankAccountType.includes('은행')"
                                                :items="bankList"
                                                v-model="teacherContent.bankName"
                                                dense
                                                chips
                                                auto-select-first
                                                label="은행">
                                </v-autocomplete>
                                <v-autocomplete v-if="teacherContent.bankAccountType && teacherContent.bankAccountType.includes('증권사')"
                                                :items="securitiesList"
                                                v-model="teacherContent.bankName"
                                                dense
                                                chips
                                                auto-select-first
                                                label="은행">
                                </v-autocomplete>
                                <v-text-field
                                        class=""
                                        v-model="teacherContent.bankAccountNumber"
                                        label="계좌번호 ('-' 제외하고 입력)"
                                        :rules="[rules.bankAccountNumberRule]"
                                        chips
                                        counter
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn
                                class="mr-3"
                                :disabled="!formValid"
                                color="secondary"
                                @click="updateTeacherBankInfo(currentUser.id, teacherContent)">
                            저장하기
                        </v-btn>
                        </div>
                        <div v-if="bankAccountInfoAvailable">
                            <v-row class="mb-8">
                                <v-col
                                        cols="12"
                                        sm="6">
                                    <v-select
                                            v-model="teacherContent.bankAccountType"
                                            :items="accountType"
                                            label="은행 / 증권"
                                            chips
                                            disabled
                                            :rules="[rules.required]"
                                    ></v-select>
                                    <v-autocomplete v-if="teacherContent.bankAccountType && teacherContent.bankAccountType.includes('은행')"
                                                    :items="bankList"
                                                    v-model="teacherContent.bankName"
                                                    dense
                                                    chips
                                                    disabled
                                                    auto-select-first
                                                    label="은행"
                                                    class="select">
                                    </v-autocomplete>
                                    <v-autocomplete v-if="teacherContent.bankAccountType && teacherContent.bankAccountType.includes('증권사')"
                                                    :items="securitiesList"
                                                    v-model="teacherContent.bankName"
                                                    dense
                                                    chips
                                                    disabled
                                                    auto-select-first
                                                    label="은행"
                                                    class="select">
                                    </v-autocomplete>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6">
                                    <v-text-field
                                            v-model="teacherContent.bankAccountNumber"
                                            label="계좌번호 ('-' 제외하고 입력)"
                                            disabled
                                            :rules="[rules.bankAccountNumberRule]"
                                            chips
                                    ></v-text-field>

                                </v-col>
                            </v-row>
                            <v-btn
                                    class="mr-3"
                                    disabled
                                    color="secondary"
                                    @click="updateTeacherBankInfo(currentUser.id, teacherContent)">
                                저장하기
                            </v-btn>
                            <v-btn
                                    v-if="bankAccountInfoAvailable == true"
                                    class="mr-3"
                                    text
                                    outlined
                                    :disable="!formValid"
                                    @click="bankAccountInfoAvailable = false">
                                수정하기
                            </v-btn>
                        </div>
                    </v-form>
                </v-card>
            </v-container>
        </v-container>

    </div>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "TeacherBankAccount",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects);
                    if (this.teacherContent.bankAccountNumber && this.teacherContent.bankAccountType && this.teacherContent.bankName) {
                        this.bankAccountInfoAvailable = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            console.log(this.teacherContent)

        },
        data() {
            return {
                bankAccountInfoAvailable: false,
                teacherContent: "",
                formValid: false,
                undergrad: "",
                demandingHoursPerWeek: info.demandingHoursPerWeek,
                demandingTotalDuration: info.demandingTotalDuration,
                bankList: info.bankList,
                securitiesList: info.securitiesList,
                accountType: info.accountType,
                selectAccountType: "",

                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                    bankAccountNumberRule: (value) => (!value.includes('-') && !!value && !(value.length < 6) ) || "'-'를 제외하고 입력하여 주세요."
                },
                possibleHighSchoolTypes: info.possibleHighSchoolTypes
            }
        },
        methods: {

            updateTeacherBankInfo(userId, requestBody) {
                requestBody.bankAccountNumber = this.teacherContent.bankAccountNumber + ""

                TeacherService.updateTeacherBankAccountInfo(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.go(0)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>
    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
